<template>
  <div class="d-flex" @click="emitLocation()">
    <div v-if="isShowBookmark" class="d-flex align-center">
      <v-icon v-show="currentBookmarked" class="starIcon" color="warning" @click.stop="switchBookmark(false)">
        mdi-star
      </v-icon>
      <v-icon v-show="!currentBookmarked" class="starIcon" @click.stop="switchBookmark(true)"> mdi-star-outline</v-icon>
    </div>
    <div class="d-flex align-center">
      <div :style="imageContainer">
        <v-img v-if="!isImageError" ref="vimage" :height="imageSize" :max-height="imageSize" :max-width="imageSize"
               :src="logo" :width="imageSize" @error="changeImageToDummy">
          <template v-slot:placeholder>
            <v-row align="center" class="fill-height ma-0" justify="center">
              <v-progress-circular color="grey lighten-2" indeterminate></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <div v-else :style="imageContainer" class="d-flex align-center justify-center">
          <v-icon x-large>mdi-google-downasaur</v-icon>
        </div>
      </div>
    </div>
    <div class="pl-2 pl-sm-3 d-flex flex-wrap d-sm-block align-center"
         style="width: calc(100% - 64px); white-space: nowrap; overflow: hidden;">
      <div style="text-overflow: ellipsis; white-space: nowrap; width: 100%; overflow: hidden;">
        {{ name || '(ไม่มีชื่อ)' }}
      </div>
      <div v-if="!uuid" class="pb-2"></div>
      <div v-if="uuid" class="mb-2 mb-sm-0"
           style="width: 100%;">
        <span class="grey--text d-none d-sm-block"
              style="font-size: 13px; text-overflow: ellipsis; white-space: nowrap; width: 100%; overflow: hidden;">
          {{ (no) ? `[ ${no} ]` : '' }} {{ address || '(ไม่มีข้อมูล)' }}
        </span>
      </div>
      <div v-if="isShowChip && !isGoldenChip" class="d-block mb-1" style="width: 100%;">
        <span v-if="isShowCemsChip" :class="cemsColor" class="white--text chip" @click="clickChip('cems')">
          CEMS
        </span>
<!--        <span v-if="!isShowCemsChip && cemsCount > 0" class="grey white&#45;&#45;text chip" @click="clickChip('cems')">-->
<!--          CEMS-->
<!--        </span>-->
        <span v-if="isShowOpmsChip" :class="opmsColor" class="white--text chip" @click="clickChip('opms')">
          WPMS
        </span>
<!--        <span v-if="!isShowOpmsChip && opmsCount > 0" class="grey white&#45;&#45;text chip" @click="clickChip('opms')">-->
<!--          WPMS-->
<!--        </span>-->
        <span v-if="isShowMobileChip" :class="mobileColor" class="white--text chip" @click="clickChip('mobile')">
          MOBILE
        </span>
<!--        <span v-if="!isShowMobileChip && mobileCount > 0" class="grey white&#45;&#45;text chip" @click="clickChip('mobile')">-->
<!--          MOBILE-->
<!--        </span>-->
        <span v-if="isShowStationChip" :class="stationColor" class="white--text chip" @click="clickChip('station')">
          STATION
        </span>
<!--        <span v-if="!isShowStationChip && stationCount > 0" class="grey white&#45;&#45;text chip" @click="clickChip('station')">-->
<!--          STATION-->
<!--        </span>-->
        <span v-if="showDistance" class="grey--text" style="font-size: 13px;">
          {{ distance }} กม. <span class="d-none d-sm-inline-block">จากตำแหน่งอ้างอิง</span>
        </span>
      </div>
      <div v-if="isShowChip && isGoldenChip && (cemsCount || opmsCount || mobileCount || stationCount)"
           class="d-none d-sm-block my-1">
        <v-chip v-if="cemsCount" class="pl-2 pr-2 mr-2" color="#A08B4F" label outlined small>
          CEMS
          <v-avatar class="gold white--text" right rounded>
            {{ cemsCount }}
          </v-avatar>
        </v-chip>
        <v-chip v-if="opmsCount" class="pl-2 pr-2 mr-2" color="#A08B4F" label outlined small>
          WPMS
          <v-avatar class="gold white--text" right rounded>
            {{ opmsCount }}
          </v-avatar>
        </v-chip>
        <v-chip v-if="mobileCount" class="pl-2 pr-2 mr-2" color="#A08B4F" label outlined small>
          MOBILE
          <v-avatar class="gold white--text" right rounded>
            {{ mobileCount }}
          </v-avatar>
        </v-chip>
        <v-chip v-if="stationCount" class="pl-2 pr-2" color="#A08B4F" label outlined small>
          STATION
          <v-avatar class="gold white--text" right rounded>
            {{ stationCount }}
          </v-avatar>
        </v-chip>
      </div>
    </div>
    <div v-if="isShowChevron" class="align-self-center" style="height: 24px; width: 24px;">
      <v-icon @click="clickChevronRight()">
        mdi-chevron-right
      </v-icon>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import utils from '../assets/js/utils'

export default {
  name: 'CompanyListItem',
  props: ['name', 'address', 'distance', 'severityCems', 'severityOpms', 'severityStation', 'logo', 'mobileCount', 'severityMobile',
    'lat', 'lon', 'isBookmarked', 'id', 'no', 'isShowChevron', 'isShowBookmark', 'isShowChip', 'activeChip', 'isGoldenChip',
    'opmsCount', 'cemsCount', 'filterMode', 'stationCount'],
  mounted () {
    if (!this.logo) {
      this.isImageError = true
    }
    this.currentBookmarked = this.isBookmarked
  },
  data () {
    return {
      isFavorite: false,
      isImageError: false,
      currentBookmarked: null
    }
  },
  methods: {
    changeImageToDummy (e) {
      this.isImageError = true
    },
    emitLocation () {
      this.$emit('clickLabel', {
        lat: this.lat,
        lon: this.lon
      })
    },
    clickChevronRight () {
      this.$emit('clickChevronRight', this.id)
    },
    clickChip (type) {
      this.$emit('clickChip', type)
      this.emitLocation()
    },
    async switchBookmark (bool) {
      const payload = {
        factoryId: this.id || '',
        uuid: this.uuid || '',
        device: this.deviceId || '',
        token: this.firebaseCloudMessageToken || '',
        platform: utils.getDeviceType(),
        is_bookmarked: (bool) ? 1 : 0
      }
      const isSuccess = await this.$store.dispatch('dashboard/setBookmarkFactory', payload)
      if (isSuccess) {
        this.currentBookmarked = bool
      }
    }
  },
  watch: {
    logo (newVal, oldVal) {
      if (!newVal) {
        this.isImageError = true
      } else if (newVal) {
        this.isImageError = false
      }
    }
  },
  computed: {
    ...mapState('user', ['uuid']),
    ...mapState(['deviceId', 'firebaseCloudMessageToken']),
    imageSize () {
      return (this.$vuetify.breakpoint.xs) ? 45 : 64
    },
    imageContainer () {
      return 'height: ' + this.imageSize + 'px; width: ' + this.imageSize + 'px;'
    },
    cemsColor () {
      let className = ''
      if (this.severityCems === 1) {
        className += 'success'
      } else if (this.severityCems === 2) {
        className += 'warning darken-1'
      } else if (this.severityCems === 3) {
        className += 'error'
      } else {
        className += 'grey'
      }
      return className
    },
    opmsColor () {
      let className = ''
      if (this.severityOpms === 1) {
        className += 'success'
      } else if (this.severityOpms === 2) {
        className += 'warning darken-1'
      } else if (this.severityOpms === 3) {
        className += 'error'
      } else {
        className += 'grey'
      }
      return className
    },
    mobileColor () {
      let className = ''
      if (this.severityMobile === 1) {
        className += 'success'
      } else if (this.severityMobile === 2) {
        className += 'warning darken-1'
      } else if (this.severityMobile === 3) {
        className += 'error'
      } else {
        className += 'grey'
      }
      return className
    },
    stationColor () {
      let className = ''
      if (this.severityStation === 1) {
        className += 'success'
      } else if (this.severityStation === 2) {
        className += 'warning darken-1'
      } else if (this.severityStation === 3) {
        className += 'error'
      } else {
        className += 'grey'
      }
      return className
    },
    showDistance () {
      return this.$route.name === 'Sensor'
    },
    isShowCemsChip () {
      return [0, 1, 2, 3].includes(this.severityCems) && (!this.filterMode || this.filterMode === 'cems')
    },
    isShowOpmsChip () {
      return [0, 1, 2, 3].includes(this.severityOpms) && (!this.filterMode || this.filterMode === 'opms')
    },
    isShowMobileChip () {
      return [0, 1, 2, 3].includes(this.severityMobile) && (!this.filterMode || this.filterMode === 'mobile')
    },
    isShowStationChip () {
      return [0, 1, 2, 3].includes(this.severityStation) && (!this.filterMode || this.filterMode === 'station')
    }
  }
}
</script>

<style scoped>
.chip {
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 24px !important;
  margin-right: 6px;
  z-index: 6;
}

@media (max-width: 600px) {
  .starIcon {
    font-size: 20px;
  }
}

::v-deep .v-chip .v-avatar {
  height: 18px !important;
  width: 18px !important;
  min-width: 18px;
}

.chip:hover {
  /*background-color: #1867C0 !important;*/
  /*border: 2px solid #1867C0 !important;*/
}
</style>
