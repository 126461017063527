import { render, staticRenderFns } from "./imageGallery.vue?vue&type=template&id=39858107&scoped=true&"
import script from "./imageGallery.vue?vue&type=script&lang=js&"
export * from "./imageGallery.vue?vue&type=script&lang=js&"
import style0 from "./imageGallery.vue?vue&type=style&index=0&id=39858107&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39858107",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCarousel,VCarouselItem,VDialog,VIcon,VImg,VRow,VSheet})
