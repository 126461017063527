<template>
  <v-dialog v-model="isOpenDialog" :max-width="(!querySuccess) ? 900 : 600" :scrollable="!$vuetify.breakpoint.xs">
    <v-card>
      <v-card-title v-if="!querySuccess">
        <span v-if="mode==='edit'" class="search--text">แก้ไขข้อมูล</span>
        <span v-else-if="mode==='add'" class="search--text">เพิ่มข้อมูล</span>
        <span v-else-if="mode==='read'" class="search--text">ข้อมูล</span>
        <span v-else class="search--text">Unexpected error code:{{ mode }}</span>
        <v-spacer/>
        <v-icon @click="isOpenDialog=false">mdi-close</v-icon>
      </v-card-title>
      <v-card-title v-else>
        <v-spacer/>
        <v-icon @click="isOpenDialog=false">mdi-close</v-icon>
      </v-card-title>
      <v-divider v-if="!querySuccess"></v-divider>
      <v-card-text class="pa-0" @scroll="mapResize">
        <div v-show="!querySuccess" class="py-3 py-sm-3 px-9 px-sm-12 black--text">
          <v-form ref="form">
            <v-row>
              <v-col class="d-flex align-center pb-0 pb-sm-3" cols="12" sm="3">
                รหัสจุดตรวจวัด
              </v-col>
              <v-col cols="">
                <v-text-field v-model="data.code" readonly color="search"
                              dense hide-details outlined placeholder="รหัสจุดตรวจวัด"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center pb-0 pb-sm-3" cols="12" sm="3">
                ชื่อจุดตรวจวัด
              </v-col>
              <v-col cols="">
                <v-text-field v-model="data.measName" :readonly="mode==='read'" color="search"
                              dense hide-details outlined placeholder="ชื่อจุดตรวจวัด"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center pb-0 pb-sm-3" cols="12" sm="3">
                ประเภท
              </v-col>
              <v-col cols="">
                <v-select v-model="data.type" :hide-details="!(mode==='add' && data.type)" :hint="(mode==='add' && data.type) ? 'การปรับแก้ประเภทจุดตรวจวัด จะเป็นการล้างค่าพารามิเตอร์ที่เลือก' : undefined"
                          :items="measurementType" :readonly="mode==='read' || mode==='edit'"
                          :rules="[rules.required]"
                          color="search" dense item-color="search" item-text="name"
                          item-value="id" outlined persistent-hint placeholder="--เลือก--"></v-select>
              </v-col>
            </v-row>
            <v-row v-if="data.type === 1 || data.type === 2">
              <v-col cols="0" sm="3"></v-col>
              <v-col class="pt-0 pt-sm-3">
                <div class="rounded-lg pa-4" style="border: 2px solid #DCDCDC">
                  <v-row v-if="data.type === 2">
                    <v-col class="d-flex align-center pb-0 pb-sm-3" cols="12" sm="5">
                      <span>ปริมาณการระบายน้ำทิ้ง <strong>สูงสุด</strong> (ลบ.ม. / วัน)</span>
                    </v-col>
                    <v-col cols="12" sm="">
                      <v-text-field v-model="data.volume_drain_water_max" :readonly="mode==='read'" dense hide-details
                                    outlined type="number"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="data.type === 2">
                    <v-col class="d-flex align-center pb-0 pb-sm-3" cols="12" sm="5">
                      <span>ปริมาณการระบายน้ำทิ้ง <strong>ต่ำสุด</strong> (ลบ.ม. / วัน)</span>
                    </v-col>
                    <v-col cols="12" sm="">
                      <v-text-field v-model="data.volume_drain_water_min" :readonly="mode==='read'" dense hide-details
                                    outlined type="number"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="data.type === 2">
                    <v-col class="d-flex align-center pb-0 pb-sm-3" cols="12" sm="5">
                      <span>ปริมาณการระบายน้ำทิ้ง <strong>ค่าเฉลี่ย</strong> (ลบ.ม. / วัน)</span>
                    </v-col>
                    <v-col cols="12" sm="">
                      <v-text-field v-model="data.volume_drain_water_avg" :readonly="mode==='read'" dense hide-details
                                    outlined type="number"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="data.type === 1">
                    <v-col class="d-flex align-center pb-0 pb-sm-3" cols="12" sm="5">
                      ระบบการเผาไหม้เชื้อเพลิง
                    </v-col>
                    <v-col cols="12" sm="">
                      <v-radio-group v-model="data.system_fuel" :readonly="mode==='read'" dense hide-details row
                                     style="margin-top: 0;">
                        <v-radio v-for="option in systemFuelStatus" :key="option.name" :label="option.name"
                                 :value="option.id"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row v-if="data.type === 1">
                    <v-col class="d-flex align-center pb-0 pb-sm-3" cols="12" sm="5">
                      <span>อัตราการระบายอากาศเสียออกจากปล่องระบาย <strong>สูงสุด</strong> (ลบ.ม. / ชม.)</span>
                    </v-col>
                    <v-col cols="12" sm="">
                      <v-text-field v-model="data.rate_drain_air_max" :readonly="mode==='read'" dense hide-details
                                    outlined type="number"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="data.type === 1">
                    <v-col class="d-flex align-center pb-0 pb-sm-3" cols="12" sm="5">
                      <span>อัตราการระบายอากาศเสียออกจากปล่องระบาย <strong>ต่ำสุด</strong> (ลบ.ม. / ชม.)</span>
                    </v-col>
                    <v-col cols="12" sm="">
                      <v-text-field v-model="data.rate_drain_air_min" :readonly="mode==='read'" dense hide-details
                                    outlined type="number"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="data.type === 1">
                    <v-col class="d-flex align-center pb-0 pb-sm-3" cols="12" sm="5">
                      <span>อัตราการระบายอากาศเสียออกจากปล่องระบาย <strong>ค่าเฉลี่ย</strong> (ลบ.ม. / ชม.)</span>
                    </v-col>
                    <v-col cols="12" sm="">
                      <v-text-field v-model="data.rate_drain_air_avg" :readonly="mode==='read'" dense hide-details
                                    outlined type="number"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="data.type === 1">
                    <v-col class="d-flex align-center pb-0 pb-sm-3" cols="12" sm="5">
                      ประเภทการเผาใหม้ของเชื้อเพลิง
                    </v-col>
                    <v-col cols="12" sm="">
                      <v-select v-model="data.stack_fuel" :items="stackFuelRef" :readonly="mode==='read'" dense
                                hide-details item-text="name" item-value="id" outlined
                                placeholder="--เลือก--"></v-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="data.type === 1 && data.stack_fuel === 6">
                    <v-col cols="12">
                      ชนิดของเชื้อเพลิงอื่น ๆ
                      <v-textarea v-model="data.stack_fuel_detail" :readonly="mode==='read'" class="mt-2" dense
                                  hide-details outlined placeholder="ชนิดของเชื้อเพลิงอื่น ๆ" rows="2"></v-textarea>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center pb-0 pb-sm-3" cols="12" sm="3">
                ระบบบำบัด
              </v-col>
              <v-col class="d-flex align-center" cols="12" sm="">
                <v-radio-group v-model="data.treatment_system" :readonly="mode==='read'" dense hide-details row
                               style="margin-top: 0;">
                  <v-radio v-for="option in treatmentRef" :key="option.name" :label="option.name"
                           :value="option.id"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row v-if="data.treatment_system === 2">
              <v-col class="d-flex align-center pb-0 pb-sm-3" cols="12" sm="3">
                รายละเอียดของระบบบำบัด
              </v-col>
              <v-col cols="12" sm="">
                <v-textarea v-model="data.treatment_system_detail" :readonly="mode==='read'" class="mt-2" dense
                            hide-details outlined placeholder="รายละเอียดของระบบบำบัด" rows="2"></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center pb-0 pb-sm-3" cols="12" sm="3">
                ละติจูด
              </v-col>
              <v-col cols="12" sm="">
                <v-text-field v-model="data.lat" :readonly="mode==='read'" :rules="[rules.required]" color="search" dense hide-details
                              outlined placeholder="ละติจูด" @blur="moveMarker"></v-text-field>
              </v-col>
              <v-col class="d-flex align-center pb-0 pb-sm-3" cols="12" sm="auto">
                ลองจิจูด
              </v-col>
              <v-col cols="12" sm="">
                <v-text-field v-model="data.lon" :readonly="mode==='read'" :rules="[rules.required]" color="search" dense hide-details
                              outlined placeholder="ลองจิจูด" @blur="moveMarker"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex pb-0 pb-sm-3" cols="12" sm="3">
                พารามิเตอร์
              </v-col>
              <v-col cols="12" sm="">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr class="primary">
                      <th class="text-center white--text" scope="col">Parameter</th>
                      <th class="text-center white--text" scope="col" style="max-width: 95px;">Channel</th>
                      <th class="text-center white--text" scope="col">แสดง/ซ่อน</th>
                      <th class="text-center white--text" scope="col" style="max-width: 95px;"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in data.params" :key="index">
                      <td>
                        <v-select v-model="data.params[index]"
                                  :items="parameterList.filter(parameterItem => parameterItem.id === data.params[index]).concat(remainParameterList)"
                                  dense hide-details item-text="name" item-value="id" outlined
                                  placeholder="--เลือก--"></v-select>
                      </td>
                      <td style="max-width: 95px;">
                        <!-- This data-id is use for disable autocomplete -->
                        <v-text-field v-model="data.channel[index]" :data-id="`${Math.random()}`" dense hide-details outlined step="1" type="number"
                                      @blur="data.channel=data.channel.map(item=>{return Math.max(item||0,0)})"/>
                      </td>
                      <td>
                        <v-select v-model="data.visible[index]" :items="[{id:1,name:'แสดง'},{id:0,name:'ซ่อน'}]" dense
                                  hide-details item-text="name" item-value="id" outlined></v-select>
                      </td>
                      <td style="max-width: 95px;">
                        <v-icon @click="data.params.splice(index, 1); data.visible.splice(index, 1); data.channel.splice(index, 1)">mdi-delete</v-icon>
                      </td>
                    </tr>
                    <tr v-if="mode==='add' && data.params.length <= 0 && !data.type">
                      <td class="text-center" colspan="4">
                        กรุณาเลือกประเภทจุดตรวจวัด
                      </td>
                    </tr>
                    <tr v-else-if="Array.isArray(data.params) && data.params.length <= 0">
                      <td class="text-center" colspan="4">
                        ไม่มีพารามิเตอร์ที่เลือก
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-btn v-if="remainParameterList.length > 0 && data.type" class="mt-2"
                       @click="data.params.push(null); data.visible.push(null); data.channel.push(null)">
                  <v-icon class="mr-2">mdi-plus</v-icon> เพิ่ม
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <br/>
          <span class="red--text">*เลื่อนหมุดบนแผนที่เพื่อย้ายจุดระบายมลพิษ/จุดตรวจวัด</span>
          <div id="mapDialogPoint" class="pt-1" style="height: 250px;"></div>
        </div>
        <div v-show="querySuccess" class="py-9 success--text align-center justify-center text-center"
             style="font-size: 20px;">
          บันทึกสำเร็จ
        </div>
      </v-card-text>
      <v-card-actions>
        <div class="d-flex flex-wrap justify-end" style="width: 100%">
          <v-btn elevation="0" @click="isOpenDialog=false">
            ปิด
          </v-btn>
          <v-btn v-if="!querySuccess && mode!=='read'" :disabled="isLoading" class="ml-3"
                 color="search-item white--text" elevation="0" @click="confirm()">
            บันทึก
            <v-progress-circular v-show="isLoading" class="ml-3" indeterminate size="16"></v-progress-circular>
          </v-btn>
          <div v-if="errorMessage!==''" class="text-right" style="width: 100%">
            <span class="error--text"> บันทึกไม่สำเร็จ ({{ errorMessage }})</span>
          </div>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import projectConstant from '../../../assets/js/projectConstant'

export default {
  name: 'SettingPoint',
  props: {
    isShow: Boolean,
    mode: String, // ['edit', 'add', 'read']
    value: Object,
    factoryId: Number,
    initialLat: Number,
    initialLon: Number
  },
  mounted () {
    this.$store.dispatch('ref/getMeasurementType')
    this.$store.dispatch('ref/getTreatmentSystemRef')
    this.$store.dispatch('ref/getStackFuelRef')
    this.$store.dispatch('ref/getSystemFuelStatus')
  },
  data () {
    return {
      data: {
        lat: '',
        lon: '',
        name: '',
        channel: [],
        visible: [],
        params: [],
        stack_fuel: 6,
        system_fuel: null
      },
      isOpenDialog: false,
      map: null,
      querySuccess: false,
      queryFail: false,
      parameterList: [],
      markerIcon: require('@/assets/img/marker.png'),
      rules: projectConstant.rules,
      isLoading: false,
      errorMessage: ''
    }
  },
  methods: {
    async createLongdoMap () {
      // create longdo map
      this.map = new window.longdo.Map({
        placeholder: document.getElementById('mapDialogPoint')
      })
      try {
        // manage cache longdo map layer
        await (window.longdoJsInterface.Util.cacheLongdoMapLayer(window.longdo, 'diw_front_cache', this.map))
      } catch (e) {
        console.warn('At cacheLongdoMapLayer', e)
      }
      try {
        this.map.Layers.setBase(window.longdo.Layers.POI)
        this.map.enableFilter(window.longdo.Filter.Light)
      } catch (e) {
        console.warn('At [setBase or enableFilter]', e)
      }
      this.map.Event.bind('overlayDrop', () => {
        const markerLocation = this.map.Overlays.list()[0].location()
        if (markerLocation.lat && markerLocation.lon) {
          this.data.lat = markerLocation.lat
          this.data.lon = markerLocation.lon
        }
      })
      if (this.$vuetify.breakpoint.xs) {
        this.map.Ui.Zoombar.visible(false)
      }
    },
    moveMarker () {
      if (this.data.lat && this.data.lon) {
        this.map.Overlays.list()[0].move({ lat: this.data.lat, lon: this.data.lon }, true)
        this.map.location({ lat: this.data.lat, lon: this.data.lon }, true)
      }
    },
    async remount () {
      this.$refs.form.resetValidation()
      await this.createLongdoMap()

      const consideredLocation = { lat: this.initialLat, lon: this.initialLon }
      this.map.location(consideredLocation)
      this.map.zoom(12)
      this.map.Overlays.clear()
      const marker = new window.longdo.Marker(
        consideredLocation,
        {
          title: this.data.code,
          draggable: (this.mode !== 'read'), // if mode is read, marker is not draggable
          icon: { url: this.markerIcon }
        }
      )
      this.map.Overlays.add(marker)

      if (!this.data.id) {
        return undefined
      }

      this.isLoading = true
      const measurementPromise = this.$store.dispatch('manage/getMeasurement', { uuid: this.uuid, id: this.data.id })
      const measurementData = await measurementPromise
      this.data.type = measurementData.measType.id
      this.$store.dispatch('ref/getParameterListByMeasurementType', { id_parent: this.data.type })
        .then((data) => { this.parameterList = data })
      this.data.date = measurementData.installationDate || ''
      this.data.system_fuel = (measurementData.systemFuel) ? measurementData.systemFuel.id : null
      this.data.measName = measurementData.measName

      const geom = String(measurementData.geom).replace('POINT', '').replace('(', '').replace(')', '')
      const lonlat = geom.split(' ')
      let lon = parseFloat(lonlat[0])
      let lat = parseFloat(lonlat[1])
      if (lon < -180) lon = -180
      if (lon > 180) lon = 180
      if (lat > 90) lat = 90
      if (lat < -90) lat = -90
      this.data.lon = lon
      this.data.lat = lat
      this.moveMarker()

      this.data.params = []
      this.data.visible = []
      this.data.channel = []
      Object.keys(this.data.parameters).forEach(
        parameterId => {
          this.data.params.push(parseInt(parameterId))
          const parameterObj = this.data.parameters[parameterId]
          const binaryVisible = (parameterObj.isVisible) ? 1 : 0
          this.data.visible.push(binaryVisible)
          this.data.channel.push(parameterObj.channel)
        }
      )
      this.data.rate_drain_air_max = measurementData.rateDrainAirMax || 0
      this.data.rate_drain_air_min = measurementData.rateDrainAirMin || 0
      this.data.rate_drain_air_avg = measurementData.rateDrainAirAvg || 0
      this.data.stack_fuel = (measurementData.stackFuelType) ? measurementData.stackFuelType.id : null
      this.data.stack_fuel_detail = measurementData.stackFuelDetail
      this.data.volume_drain_water_max = measurementData.volumeDrainWaterMax || 0
      this.data.volume_drain_water_min = measurementData.volumeDrainWaterMin || 0
      this.data.volume_drain_water_avg = measurementData.volumeDrainWaterAvg || 0
      this.data.treatment_system = (measurementData.treatmentSystem) ? measurementData.treatmentSystem.id || 0 : 0
      this.data.treatment_system_detail = measurementData.treatmentSystemDetail

      delete this.data.measType
      delete this.data.parameters
      delete this.data.parameter
      delete this.data.recordedDate
      delete this.data.treatmentSystemDetail
      delete this.data.typeName

      this.data = { ...this.data } // tell vue to update component
      this.isLoading = false
    },
    async confirm () {
      this.isLoading = true
      const isValidForm = this.$refs.form.validate()
      if (!isValidForm) {
        this.isLoading = false
        return false
      }
      this.data.params = this.data.params.filter(item => item !== null)
      this.data.visible = this.data.visible.filter(item => item !== null)
      this.data.channel = this.data.channel.filter(item => item !== null)
      this.data.stack_fuel = this.data.stack_fuel || ''
      this.data.system_fuel = this.data.system_fuel || ''
      if (String(this.data.type) === '1') {
        delete this.data.volume_drain_water_max
        delete this.data.volume_drain_water_min
        delete this.data.volume_drain_water_avg
        if (String(this.data.stack_fuel) !== '6') {
          delete this.data.stack_fuel_detail
        }
      } else if (String(this.data.type) === '2') {
        delete this.data.rate_drain_air_max
        delete this.data.rate_drain_air_min
        delete this.data.rate_drain_air_avg
        delete this.data.stack_fuel
        delete this.data.stack_fuel_detail
      }
      if (String(this.data.treatment_system) !== '2') {
        delete this.data.treatment_system_detail
      }
      const payload = { ...this.data }
      if (this.mode === 'add') {
        payload.id = 0
      }
      payload.uuid = this.uuid || ''
      payload.factory = this.factoryId
      if (payload.time) { // no need to use this key
        delete payload.time
      }
      this.errorMessage = ''
      const isSuccess = await this.$store.dispatch('sensor/setSensorDetail', payload)
      if (isSuccess === true) {
        // this.$emit('queryResult', true)
        this.querySuccess = true
      } else {
        // this.$emit('queryResult', false)
        this.errorMessage = isSuccess
        this.queryFail = true
      }
      this.isLoading = false
    },
    mapResize () {
      if (this.map) {
        this.map.resize()
      }
    }
  },
  watch: {
    value (newVal, oldVal) {
      this.data = newVal
    },
    isShow (newVal, oldVal) {
      if (newVal !== oldVal || newVal === true) {
        this.isOpenDialog = newVal
      }
      if (newVal === true) {
        this.querySuccess = false
        this.queryFail = false
        this.errorMessage = ''
        // wait for dialog draw finish then draw map
        setTimeout(this.remount, 500)
      }
    },
    isOpenDialog (newVal, oldVal) {
      if (newVal !== oldVal || newVal === false) {
        this.$emit('isShowSignal', newVal)
      }
      if (!newVal) this.$emit('queryResult', true)
    },
    'data.type': {
      async handler (to, from) {
        if (to !== from && this.mode === 'add' && to) this.parameterList = await this.$store.dispatch('ref/getParameterListByMeasurementType', { id_parent: to })
        if (to !== from && to && from) this.data.params = []
        // refresh longdo map
        this.$nextTick(
          () => {
            this.mapResize()
          }
        )
      },
      deep: true
    }
  },
  computed: {
    ...mapState('user', ['uuid']),
    ...mapState('ref', ['treatmentRef', 'stackFuelRef', 'systemFuelStatus', 'measurementType']),
    remainParameterList () {
      if (Array.isArray(this.data.params) && Array.isArray(this.parameterList)) {
        return this.parameterList.filter(item => !this.data.params.includes(item.id))
      } else {
        return []
      }
    }
  }
}
</script>

<style scoped>

::v-deep .row + .row {
  margin-top: 4px !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td {
  border-left: thin solid rgba(0, 0, 0, 0.12);
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:last-child {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}

::v-deep .v-data-table__wrapper > table > tbody > tr:last-child > td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
