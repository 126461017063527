<template>
  <v-dialog v-model="isOpenDialog" :max-width="(!querySuccess) ? 800 : 600" :scrollable="!$vuetify.breakpoint.xs">
    <v-card>
      <v-card-title v-if="!querySuccess">
        <span v-if="mode==='edit'" class="search--text">แก้ไขข้อมูล</span>
        <span v-if="mode==='add'" class="search--text">เพิ่มข้อมูล</span>
        <span v-if="mode==='view'" class="search--text">ข้อมูล</span>
        <v-spacer/>
        <v-icon @click="isOpenDialog=false">mdi-close</v-icon>
      </v-card-title>
      <v-card-title v-else>
        <v-spacer/>
        <v-icon @click="isOpenDialog=false">mdi-close</v-icon>
      </v-card-title>
      <v-divider v-if="!querySuccess"/>
      <v-card-text class="pa-0">
        <div v-show="!querySuccess" class="py-4 px-9 px-sm-16 black--text">
          <v-form ref="form">
            <v-row>
              <v-col class="d-flex align-center pb-0 pb-sm-3" cols="12" sm="2">
                Serial no.
              </v-col>
              <v-col cols="">
                <v-text-field v-model="data.serialNumber" :readonly="mode==='view'" color="search" dense hide-details
                              outlined placeholder="Serial no."></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center pb-0 pb-sm-3" cols="12" sm="2">
                ยี่ห้อ
              </v-col>
              <v-col cols="">
                <v-text-field v-model="data.brand" :readonly="mode==='view'" color="search" dense hide-details outlined
                              placeholder="ยี่ห้อ"></v-text-field>
              </v-col>
              <v-col class="d-flex align-center pb-0 pb-sm-3" cols="12" sm="2">
                รุ่น
              </v-col>
              <v-col cols="">
                <v-text-field v-model="data.converter" :readonly="mode==='view'" :rules="[rules.required]"
                              color="search" dense hide-details outlined placeholder="รุ่น"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center pb-0 pb-sm-3" cols="12" sm="2">
                ประเภท
              </v-col>
              <v-col cols="">
                <v-select v-model="data.deviceType" :items="deviceType" :readonly="mode==='view'"
                          :rules="[rules.required]" color="search" dense hide-details item-color="search"
                          item-text="name" item-value="id" outlined placeholder="--เลือก--"></v-select>
              </v-col>
              <v-col cols="">
                <v-text-field v-model="data.deviceDetail"
                              :background-color="!isOtherDeviceType(data.deviceType) ? 'grey lighten-3' : 'white'"
                              :disabled="!isOtherDeviceType(data.deviceType)"
                              :readonly="!isOtherDeviceType(data.deviceType) || mode==='view'" color="search" dense
                              hide-details outlined placeholder="โปรดระบุประเภท"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center pb-0 pb-sm-3" cols="12" sm="2">
                IP Address
              </v-col>
              <v-col cols="">
                <v-text-field v-model="data.ipAddress" :readonly="mode==='view'" :rules="[rules.required]"
                              color="search" dense hide-details outlined placeholder="IP Address"></v-text-field>
              </v-col>
              <v-col class="d-flex align-center pb-0 pb-sm-3" cols="12" sm="2">
                ความจุ
              </v-col>
              <v-col cols="">
                <v-text-field v-model="data.capacity" :readonly="mode==='view'" color="search" dense hide-details
                              outlined placeholder="ความจุ"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center flex-wrap text-sm-center pb-0 pb-sm-3" cols="12" sm="2">
                <div style="width: 100%;">โรงงานที่ติดตั้ง</div>
                <div style="font-size: 12px">(พิมพ์เพื่อค้นหา)</div>
              </v-col>
              <v-col cols="">
                <v-autocomplete v-model="data.factory.id" :items="allFactory" :loading="isSearching"
                                readonly :rules="[rules.required]" color="search" dense hide-details
                                item-text="name" item-value="id" outlined
                                placeholder="ค้นหาโรงงานที่ติดตั้ง"></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                ประเภทสัญญาณ/วิธีการส่งข้อมูล
                <v-textarea v-model="data.method" :readonly="mode==='view'" class="mt-2" hide-details outlined
                            placeholder="โปรดระบุ" rows="2"></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <div v-show="querySuccess" class="py-9 success--text align-center justify-center text-center"
             style="font-size: 20px;">
          บันทึกสำเร็จ
        </div>
      </v-card-text>
      <v-card-actions>
        <div class="d-flex flex-wrap justify-end" style="width: 100%">
          <v-btn elevation="0" @click="isOpenDialog=false">
            ปิด
          </v-btn>
          <v-btn v-if="!querySuccess && mode!=='view'" :disabled="isFetch" class="ml-3"
                 color="search-item white--text" elevation="0" @click="confirm()">
            บันทึก
            <v-progress-circular v-show="isFetch" class="ml-3" indeterminate size="16"></v-progress-circular>
          </v-btn>
          <div v-if="errorMessage!==''" class="text-right" style="width: 100%">
            <span class="error--text"> บันทึกไม่สำเร็จ ({{ errorMessage }})</span>
          </div>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import projectConstant from '../../../assets/js/projectConstant'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'InstrumentDialog',
  props: {
    isShow: Boolean,
    value: Object,
    mode: String, // ['edit', 'add', 'view']
    initialFactory: Object
  },
  mounted () {
    if (this.initialFactory && this.mode === 'add') {
      this.data.factory.id = this.initialFactory.id
    }
    this.$store.dispatch('ref/getDeviceType')
    this.$store.dispatch('ref/getFactoryAll')
  },
  data () {
    return {
      isFetch: false,
      data: {
        factory: {
          id: '',
          name: ''
        }
      },
      isOpenDialog: false,
      map: null,
      isSearching: false,
      search: '',
      querySuccess: false,
      queryFail: false,
      rules: projectConstant.rules,
      errorMessage: ''
    }
  },
  methods: {
    async confirm () {
      const isFormValid = this.$refs.form.validate()
      if (!isFormValid) {
        return
      }
      const payload = {
        uuid: this.uuid,
        ip_addr: this.data.ipAddress,
        converter: this.data.converter,
        device: this.data.deviceType,
        device_detail: (String(this.data.deviceType) === '2') ? this.data.deviceDetail : '',
        factory: this.data.factory.id,
        brand: this.data.brand || '',
        capacity: this.data.capacity || '',
        method: this.data.method || '',
        serial: this.data.serialNumber || '',
        id: this.data.id
      }
      this.isFetch = true
      this.errorMessage = ''
      const isSuccess = await this.$store.dispatch('manage/setDevice', payload)
      this.isFetch = false
      if (isSuccess === true) {
        this.querySuccess = true
        this.$emit('queryResult', true)
      } else {
        this.errorMessage = isSuccess
        this.queryFail = true
        this.$emit('queryResult', false)
      }
    },
    async fetch () {
      const id = this.data.id
      const payload = { uuid: this.uuid, id: id }
      if (payload.id) {
        const device = await this.$store.dispatch('manage/getDevice', payload)
        this.data = {
          ...this.data,
          method: device.method,
          capacity: device.capacity,
          deviceDetail: device.deviceDetail,
          deviceType: device.deviceType.id,
          ipAddress: device.ipAddress
        }
      }
    }
  },
  watch: {
    value (newVal, oldVal) {
      this.data = newVal
      this.fetch()
    },
    isShow (newVal, oldVal) {
      if (newVal !== oldVal || newVal === true) {
        this.isOpenDialog = newVal
      }
      if (newVal === true) {
        this.querySuccess = false
        this.queryFail = false
        this.errorMessage = ''
        setTimeout(() => {
          if (this.$refs.form) {
            this.$refs.form.resetValidation()
          }
        }, 100)
      }
      if (this.initialFactory && this.mode === 'add') {
        this.data.factory.id = this.initialFactory.id
      }
    },
    isOpenDialog (newVal, oldVal) {
      if (newVal !== oldVal || newVal === false) {
        this.$emit('isShowSignal', newVal)
      }
      if (this.initialFactory && this.mode === 'add') {
        this.data.factory.id = this.initialFactory.id
      }
    },
    'data.deviceType' (to, from) {
      const isOther = this.isOtherDeviceType(to)
      if (!isOther) {
        this.data.deviceDetail = ''
      }
    }
  },
  computed: {
    ...mapState('user', ['uuid']),
    ...mapState('ref', ['deviceType', 'allFactory']),
    ...mapGetters('ref', ['isOtherDeviceType'])
  }
}
</script>

<style scoped>
::v-deep .row + .row {
  margin-top: 9px;
}

::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 99%;
}
</style>
