<template>
  <div style="height: 100%;">
    <v-container style="height: 100%;">
      <div id="setting-device-header" class="d-flex flex-wrap mt-2" style="width: 100%;">
        <div class="d-none d-sm-block">
          <span style="font-size: 24px; color: #00236F;">ตั้งค่าอุปกรณ์เก็บบันทึกข้อมูล</span>
        </div>
        <v-spacer class="d-flex d-sm-none"/>
        <div class="CRUD_box">
          <v-btn v-if="permission.includes('manage device')" class="addButton" @click="addItem()">
            <v-icon>mdi-plus</v-icon>
            เพิ่มอุปกรณ์
          </v-btn>
<!--          <v-spacer v-if="$vuetify.breakpoint.width > 760"/>-->
<!--          <div style="max-width: 300px;">-->
<!--            <v-text-field v-model="keyword" append-icon="mdi-close" dense hide-details outlined placeholder="ค้นหาด้วยชื่อ"-->
<!--                          prepend-inner-icon="mdi-magnify" @keydown.enter="get(true)" @click:append="keyword=''"/>-->
<!--          </div>-->
        </div>
      </div>
      <div :style="`height: calc(100% - ${headerHeight}px);`" class="pt-5">
        <v-data-table :headers="permissionHeader" :items="data" :items-per-page="999" disable-sort fixed-header
                      hide-default-footer mobile-breakpoint="0" style="height: 100%;">
          <template v-slot:item.actions="{ item }">
            <v-icon v-if="permission.includes('view device info') && !permission.includes('manage device')" class="mr-2"
                    @click="viewItem(item)">mdi-magnify
            </v-icon>
            <v-icon v-if="permission.includes('manage device')" class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon v-if="permission.includes('manage device')" @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </div>
    </v-container>
    <instrument-dialog v-model="dialogItem" :isShow="openDialog" :initialFactory="initialFactory" :mode="dialogMode"
                       v-on:isShowSignal="signalHandler"
                       v-on:queryResult="handleQueryResult"/>
    <v-dialog v-model="openDeleteDialog" max-width="600">
      <v-card>
        <v-card-title>
          <v-spacer/>
          <v-icon @click="openDeleteDialog=false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pa-0">
          <div class="py-9 black--text text-center" style="font-size: 20px;">
            <span v-if="!isFinishDelete && !isFailDelete">ยืนยันการลบ "<span
              class="error--text">{{ deleteSelect.serialNumber }}</span>"</span>
            <span v-else-if="isFinishDelete" class="success--text">สำเร็จ</span>
            <span v-else-if="isFailDelete" class="warning--text">ไม่สำเร็จ</span>
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="d-flex justify-end" style="width: 100%">
            <v-btn elevation="0" @click="openDeleteDialog=false">
              ปิด
            </v-btn>
            <v-btn v-if="!isFinishDelete && !isFailDelete" class="ml-3" color="search-item white--text" elevation="0"
                   @click="confirmDelete()">
              ยืนยัน
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import InstrumentDialog from '../dialog/setting/InstrumentDialog'
import { mapState } from 'vuex'

export default {
  name: 'InstrumentInformation',
  components: { InstrumentDialog },
  props: ['initialFactory'],
  mounted () {
    if (this.initialFactory.name) {
      this.keyword = this.initialFactory.name
    }
    this.reCalculateHeight()
    window.addEventListener('resize', this.reCalculateHeight)
    this.get()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.reCalculateHeight)
  },
  data () {
    return {
      headerHeight: 40,
      headers: [
        {
          text: 'Serial no.',
          value: 'serialNumber'
        },
        {
          text: 'ยี่ห้อ',
          value: 'brand'
        },
        {
          text: 'รุ่น',
          value: 'converter'
        },
        {
          text: 'ชื่อโรงงาน',
          value: 'factory.name'
        }
      ],
      data: [],
      keyword: '',
      page: 1,
      hasNextPage: true,
      maxPage: 999,
      editedIndex: -1,
      dialogItem: {},
      dialogMode: '',
      openDialog: false,
      openDeleteDialog: false,
      isFinishDelete: false,
      isFailDelete: false,
      deleteSelect: {}
    }
  },
  methods: {
    reCalculateHeight () {
      const dom = document.getElementById('setting-device-header')
      if (dom) {
        this.headerHeight = dom.offsetHeight
      }
    },
    async get (clear = false) {
      const payload = {
        uuid: this.uuid,
        keyword: this.keyword,
        page: this.page
      }
      const result = await this.$store.dispatch('manage/getDeviceList', payload)
      if (Array.isArray(result) && result.length === 0) {
        this.hasNextPage = false
        return
      }
      this.maxPage = result.maxPage
      this.hasNextPage = result.hasNextPage
      if (clear) {
        this.data = result.items
      } else {
        this.data = [...this.data, ...result.items]
      }
    },
    signalHandler (e) {
      if (e !== this.openDialog || e === false) {
        this.openDialog = e
      }
    },
    handleQueryResult (e) {
      if (e) {
        this.get(true)
      }
    },
    addItem () {
      this.dialogItem = {
        id: 0,
        factory: {
          id: -1,
          name: 'not implemented yet'
        }
      }
      this.dialogMode = 'add'
      this.openDialog = true
    },
    viewItem (item) {
      this.editedIndex = this.data.indexOf(item)
      this.dialogMode = 'view'
      this.dialogItem = { ...item }
      this.openDialog = true
    },
    editItem (item) {
      this.editedIndex = this.data.indexOf(item)
      this.dialogMode = 'edit'
      this.dialogItem = { ...item }
      this.openDialog = true
    },
    deleteItem (item) {
      this.deleteSelect = item
      this.isFinishDelete = false
      this.isFailDelete = false
      this.openDeleteDialog = true
    },
    async confirmDelete () {
      const payload = {
        id: this.deleteSelect.id,
        uuid: this.uuid || ''
      }
      const isSuccess = await this.$store.dispatch('manage/deleteDevice', payload)
      if (isSuccess) {
        this.isFinishDelete = true
        await this.get(true)
      } else {
        this.isFailDelete = true
      }
    }
  },
  watch: {
    initialFactory: {
      handler (to, from) {
        if (to.id && to.id !== from.id) {
          this.keyword = to.name || ''
          this.get(true)
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState('user', ['uuid', 'permission']),
    permissionHeader () {
      const width = (this.permission.includes('manage device')) ? 100 : 50
      if (this.permission.includes('manage device') || this.permission.includes('view device info')) {
        return [...this.headers, { text: '', sortable: false, value: 'actions', width: width }]
      } else {
        return this.headers
      }
    }
  }
}
</script>

<style scoped>
@media (max-width: 600px) {
  .CRUD_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 300px;
  }

  .addButton {
    margin: 0 0 12px 0;
  }
}

@media (min-width: 600px) {
  .CRUD_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: calc(100% - 330px);
  }

  .addButton {
    margin: 0 0 12px 24px;
  }
}

::v-deep .v-data-table__wrapper {
  /*height:calc(100% - 150px) !important;*/
  max-height: 100%;
  overflow-y: unset;
}

::v-deep .v-data-table-header > tr > th {
  background-color: #D8D8D8 !important;
  padding: 0 8px !important;
  color: black;
}

::v-deep .v-data-table-header > tr > th {
  border-left: thin solid rgba(0, 0, 0, 0.3) !important;
  border-top: thin solid rgba(0, 0, 0, 0.3) !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table-header > tr > th:last-child {
  border-right: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:first-child > td {
  border-top: unset;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:first-child {
  border-left: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:last-child {
  border-right: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:last-child > td {
  border-bottom: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:not(:first-child) {
  border-left: thin solid rgba(0, 0, 0, 0.12) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:hover {
  background-color: #e5eeff !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:nth-child(odd) {
  background-color: #F6F6F6;
}
</style>
