<template>
  <div class="d-flex pb-2" style="width: 100%; overflow-x: auto;">
    <div v-for="(imageSrc, index) in data.uploadedImages" :key="data.uploadedImages.findIndex(item => item.id === imageSrc.id)" class="imageContainer">
      <v-img :src="imageSrc.src" max-width="128" max-height="128" style="cursor: pointer;"
             @click="isOpenDialog=true; carouselIndex=index"></v-img>
      <v-icon x-small class="white black--text rounded-xl removeBtnContainer"
              @click="data.uploadedImages.splice(index, 1); data.tobeRemoveImages.push(imageSrc.id); emitInput();">
        mdi-close
      </v-icon>
    </div>
    <div v-for="(imageObject, index) in data.tobeUploadImages" :key="imageObject.name + index" class="imageContainer">
      <v-img :src="getPseudoUrlFromObject(imageObject)" max-width="128" max-height="128" style="cursor: pointer;"
             @click="isOpenDialog=true; carouselIndex=index+data.uploadedImages.length"></v-img>
      <v-icon x-small class="white black--text rounded-xl removeBtnContainer"
              @click="data.tobeUploadImages.splice(index, 1); emitInput()">
        mdi-close
      </v-icon>
      <div v-if="!isValidFileType(imageObject)" style="position: absolute; bottom: 0; padding: 2px; font-size: 11px; border: 1px solid black; background-color: white; color: red;">
        ต้องการไฟล์ประเภท jpg หรือ png เท่านั้น
      </div>
    </div>
    <div v-if="canShowAddBtn" key="addImage" class="d-flex text-center justify-center align-center rounded-lg hoverClick"
         style="min-height: 128px; min-width: 128px; max-height: 128px; max-width: 128px;"
         @click="openUploadButton">
      เพิ่มรูปภาพ คลิก
    </div>
    <input ref="fileInput" class="d-none" type="file" accept=".png, .jpg, .jpeg" :multiple="limit!==1" @change="handleFileChange">
    <v-dialog v-model="isOpenDialog">
      <v-carousel v-model="carouselIndex" hide-delimiter-background style="position: relative;">
        <v-carousel-item v-for="src in allImageSrc" :key="src" class="black">
          <v-sheet color="black" height="100%" tile>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-img :src="src" contain :max-height="500"></v-img>
            </v-row>
          </v-sheet>
        </v-carousel-item>
        <v-icon class="white black--text rounded-xl pa-1" style="position: absolute; top: 8px; right: 8px;"
                @click="isOpenDialog=false">
          mdi-close
        </v-icon>
      </v-carousel>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'imageGallery',
  props: ['value', 'limit'],
  mounted () {
    this.data = this.value
  },
  data () {
    return {
      data: {
        tobeUploadImages: [],
        uploadedImages: [],
        tobeRemoveImages: []
      },
      isOpenDialog: false,
      carouselIndex: 0
    }
  },
  methods: {
    openUploadButton () {
      this.$refs.fileInput.click()
    },
    handleFileChange (e) {
      const selectFile = [...this.$refs.fileInput.files]
      this.data.tobeUploadImages = [...this.data.tobeUploadImages, ...selectFile]
      this.$refs.fileInput.value = null // if not set to null, next time when select same file @change will not detect
      this.emitInput()
    },
    getPseudoUrlFromObject (obj) {
      return URL.createObjectURL(obj)
    },
    resetComponent () {
      this.data.tobeUploadImages = []
      this.$refs.fileInput.value = null
    },
    emitInput () {
      this.$emit('input', this.data)
    },
    isValid () {
      const validArray = this.data.tobeUploadImages.map(item => this.isValidFileType(item))
      return validArray.reduce((accumulator, current) => accumulator && current, true)
    },
    isValidFileType (file) {
      if (!file.name) return false
      const extension = file.name.split('.').slice(-1).pop().toLowerCase()
      return ['jpg', 'jpeg', 'png'].includes(extension)
    }
  },
  watch: {
    value: {
      handler (to, from) {
        if (to !== from) this.data = this.value
      },
      deep: true
    }
  },
  computed: {
    allImageSrc () {
      return [...this.data.uploadedImages.map(item => item.src), ...this.data.tobeUploadImages.map(item => this.getPseudoUrlFromObject(item))]
    },
    canShowAddBtn () {
      if (!this.limit) return true
      return this.data.uploadedImages.length + this.data.tobeUploadImages.length < this.limit
    }
  }
}
</script>

<style scoped>
.imageContainer {
  margin-right: 16px;
  position: relative;
  display: flex;
  align-content: center;
  width: 128px;
  height: 128px;
}
.removeBtnContainer {
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 2px;
}
.hoverClick {
  background-color: #eeeeee;
  border-color: #eeeeee;
}
.hoverClick:hover {
  background-color: #fafafa !important;
  border: 1px solid black;
  cursor: pointer;
}
.slide-list-move {
  transition: transform 1s;
}
</style>
