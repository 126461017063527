<template>
  <div>
    <v-data-table :headers="headers" :items="items" :items-per-page="999" disable-sort hide-default-footer
                  mobile-breakpoint="0">
      <template v-slot:item.parameter="{ item }">
        <span v-if="item.parameter.length > 5" style="font-size: 12px;">{{ item.parameter.join(', ') }}</span>
        <span v-else>{{ item.parameter.join(', ') }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon v-if="permission.includes('view measurement info') && !permission.includes('manage measurement')"
                class="myPencil" @click="emitViewSignal(item)">
          mdi-magnify
        </v-icon>
        <v-icon v-if="permission.includes('manage measurement')"
                class="mr-2 myPencil" @click="emitEditSignal(item)">
          mdi-pencil
        </v-icon>
        <v-icon v-if="permission.includes('manage measurement')"
                class="myTrash" @click="emitDeleteSignal(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import utils from '../assets/js/utils'
import { mapState } from 'vuex'

export default {
  name: 'measurementTable',
  props: ['sensorData', 'showAction', 'type'],
  mounted () {
    this.$store.dispatch('ref/getParameterList')
  },
  data () {
    return {}
  },
  methods: {
    emitEditSignal (item) {
      this.$emit('editBtn', item)
    },
    emitDeleteSignal (item) {
      this.$emit('deleteBtn', item)
    },
    emitViewSignal (item) {
      this.$emit('viewBtn', item)
    }
  },
  computed: {
    ...mapState('ref', ['parameterList']),
    ...mapState('user', ['permission']),
    items () {
      if (!this.sensorData.measurements) {
        return []
      }
      const key = Object.keys(this.sensorData.measurements)
      const data = key.map(k => (this.sensorData.measurements[k]))
      const mapped = data.map(
        item => {
          const ret = {
            code: item.code,
            parameter: Object.keys(item.parameters)
              .map(
                parameterId => this.parameterList.find(item => String(item.id) === String(parameterId)).name
              )
              .map(
                text => text.replace(/ *\([^)]*\) */g, '')) // remove parenthesis and it's content
              .sort(
                (a, b) => {
                  return a.localeCompare(b) // alphabet sort
                }
              ),
            date: utils.dateToThai(new Date().toISOString().substr(0, 10)),
            treatment_system: item.treatmentSystemDetail || '',
            air_rate: '-',
            fuel_type: '-',
            water_rate: '-',
            ...item
          }
          return ret
        }
      )
      return mapped
    },
    headers () {
      const tmpHeader = [
        {
          text: 'จุดตรวจวัด',
          value: 'measName',
          align: 'center'
        },
        {
          text: 'พารามิเตอร์',
          value: 'parameter'
        }
      ]
      let actions = []
      if (this.showAction) {
        actions = [
          {
            text: '',
            value: 'actions',
            align: 'center',
            width: 95
          }
        ]
      }
      return [...tmpHeader, ...actions]
    }
  }
}
</script>

<style scoped>
::v-deep .v-data-table-header > tr > th {
  background-color: #D8D8D8 !important;
  padding: 0 8px !important;
  color: black;
}

::v-deep .v-data-table-header > tr > th:not(:first-child) {
  border-left: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:nth-child(odd) {
  background-color: #F6F6F6;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td {
  border-left: thin solid rgba(0, 0, 0, 0.12) !important;
  padding: 0 8px;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:last-child > td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:hover {
  background-color: #e5eeff !important;
}
</style>
