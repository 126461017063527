<template>
  <v-dialog v-model="isOpenDialog" :max-width="!isQuerySuccess ? 900 : 600" scrollable>
    <v-card>
      <v-card-title v-if="!isQuerySuccess">
        <span v-if="mode==='edit'" class="search--text">แก้ไขโรงงาน/บริษัท</span>
        <span v-else-if="mode==='add'" class="search--text">เพิ่มโรงงาน/บริษัท</span>
        <span v-else-if="mode==='read'" class="search--text">โรงงาน/บริษัท</span>
        <span v-else class="search--text">Unexpected error code:{{ mode }}</span>
        <v-spacer/>
        <v-icon @click="isOpenDialog=false">mdi-close</v-icon>
      </v-card-title>
      <v-card-title v-else>
        <v-spacer/>
        <v-icon @click="isOpenDialog=false">mdi-close</v-icon>
      </v-card-title>
      <v-divider v-if="!isQuerySuccess"></v-divider>
      <v-card-text class="pa-0" @scroll="mapResize">
        <div v-show="!isQuerySuccess" class="py-3 py-sm-3 px-6 px-sm-9 black--text">
          <v-form ref="form">
            <v-row>
              <v-col class="d-flex align-center pb-0 pb-sm-3 text-sm-right justify-sm-end" cols="12" sm="3">
                เลขทะเบียนโรงงาน (เก่า)
              </v-col>
              <v-col cols="">
                <v-text-field v-model="data.no" :background-color="(mode==='read') ? 'grey lighten-5' : ''"
                              :disabled="mode==='read'" :readonly="mode==='read'"
                              :rules="(mode!=='add') ? [] : [rules.required]" color="search" dense hide-details outlined
                              placeholder="เลขทะเบียนโรงงาน (เก่า)"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center pb-0 pb-sm-3 text-sm-right justify-sm-end" cols="12" sm="3">
                เลขทะเบียนโรงงาน (ใหม่)
              </v-col>
              <v-col cols="">
                <v-text-field v-model="data.noNew" :background-color="(mode==='read') ? 'grey lighten-5' : ''"
                              :disabled="mode==='read'" :readonly="mode==='read'"
                              :rules="(mode!=='add') ? [] : [rules.required]" color="search" dense hide-details outlined
                              placeholder="เลขทะเบียนโรงงาน (ใหม่)"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center pb-0 pb-sm-3 text-sm-right justify-sm-end" cols="12" sm="3">
                ชื่อโรงงาน/บริษัท
              </v-col>
              <v-col cols="">
                <v-text-field v-model="data.name" :readonly="mode==='read'" :rules="[rules.required]" color="search"
                              dense hide-details outlined placeholder="ชื่อโรงงาน/บริษัท"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center pb-0 pb-sm-3 text-sm-right justify-sm-end" cols="12" sm="3">
                ข้อมูลที่ตั้ง
              </v-col>
              <v-col cols="12" sm="9">
                <v-text-field v-model="data.address" :readonly="mode==='read'" color="search" dense hide-details
                              outlined placeholder="ข้อมูลที่ตั้ง"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center pb-0 pb-sm-3 text-sm-right justify-sm-end" cols="12" sm="3">
                ประเภทอุตสาหกรรม
              </v-col>
              <v-col cols="12" sm="9">
                <v-autocomplete v-model="data.type.id" :items="forSelectFactoryType"
                                :placeholder="(mode==='edit') ? '--เลือก--' : ''" :readonly="mode==='read'"
                                :rules="[rules.required]" color="search" dense hide-details item-color="search"
                                item-text="name" item-value="id" outlined/>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center pb-0 pb-sm-3 text-sm-right justify-sm-end" cols="12" sm="3">
                การประกอบการ
              </v-col>
              <v-col cols="d-flex align-center">
                <v-select v-model="data.type_industry" :items="industryType"
                          :placeholder="(mode==='edit') ? '--เลือก--' : ''" :readonly="mode==='read'" color="search"
                          dense hide-details item-color="search" item-text="name" item-value="id" outlined/>
              </v-col>
            </v-row>
            <v-row v-if="data.type_industry===2">
              <v-col class="d-flex align-center pb-0 pb-sm-3 text-sm-right justify-sm-end" cols="12" sm="3">
                เขต/สวนอุตสาหกรรม
              </v-col>
              <v-col cols="d-flex align-center">
                <v-autocomplete v-model="data.zone" :items="zone" :placeholder="(mode==='edit') ? '--เลือก--' : ''"
                                :readonly="mode==='read'" color="search" dense hide-details item-color="search"
                                item-text="name" item-value="id" outlined/>
              </v-col>
            </v-row>
            <v-row v-if="data.type_industry===3">
              <v-col class="d-flex align-center pb-0 pb-sm-3 text-sm-right justify-sm-end" cols="12" sm="3">
                นิคมอุตสาหกรรม
              </v-col>
              <v-col cols="d-flex align-center">
                <v-autocomplete v-model="data.colony" :items="colonyIndustry"
                                :placeholder="(mode==='edit') ? '--เลือก--' : ''" :readonly="mode==='read'"
                                color="search" dense hide-details item-color="search" item-text="name" item-value="id"
                                outlined/>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center pb-0 pb-sm-3 text-sm-right justify-sm-end" cols="12" sm="3">
                ประเภทบุคคลตามกฏหมาย
              </v-col>
              <v-col cols="d-flex align-center">
                <v-radio-group v-model="selectedPersonType" :readonly="mode==='read'" hide-details row>
                  <v-radio v-for="item in personType" :key="item.id" :label="item.name" :value="item.id"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center pb-0 pb-sm-3 text-sm-right justify-sm-end" cols="12" sm="3">
                อีเมลสำหรับการแจ้งเตือน (โรงงาน)
              </v-col>
              <v-col cols="12" sm="9">
                <v-text-field v-model="data.emailFactory" :readonly="mode==='read'" color="search" dense hide-details
                              outlined placeholder="อีเมลสำหรับการแจ้งเตือน (โรงงาน)"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center pb-0 pb-sm-3 text-sm-right justify-sm-end" cols="12" sm="3">
                อีเมลสำหรับการแจ้งเตือน (เจ้าหน้าที่)
              </v-col>
              <v-col cols="12" sm="9">
                <v-text-field v-model="data.emailStaff" :readonly="mode==='read'" color="search" dense hide-details
                              outlined placeholder="อีเมลสำหรับการแจ้งเตือน (เจ้าหน้าที่)"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex pb-0 pb-sm-3 text-sm-right justify-sm-end" cols="12" sm="3">
                โลโก้โรงงาน
              </v-col>
              <v-col cols="12" sm="9">
                <image-gallery ref="logoGallery" v-model="logo" :limit="1"></image-gallery>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex pb-0 pb-sm-3 text-sm-right justify-sm-end" cols="12" sm="3">
                ภาพโรงงาน
              </v-col>
              <v-col cols="12" sm="9">
                <image-gallery ref="imageGallery" v-model="images"/>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col class="d-flex pb-0 pb-sm-3 text-sm-right justify-sm-end" cols="12" sm="3">
                ข้อมูลผู้ติดต่อ
              </v-col>
              <v-col cols="12" sm="9">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr class="primary">
                      <th class="text-center white--text" scope="col">ชื่อ</th>
                      <th class="text-center white--text" scope="col" style="max-width: 135px;">ตำแหน่ง</th>
                      <th class="text-center white--text" scope="col" style="max-width: 150px;">หมายเลขโทรศัพท์</th>
                      <th class="text-center white--text" scope="col" style="max-width: 95px;"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(obj, index) in data.contact" :key="index">
                      <td>
                        <v-text-field v-model="obj.name" dense hide-details outlined placeholder="ชื่อ"></v-text-field>
                      </td>
                      <td style="max-width: 135px;">
                        <v-text-field v-model="obj.position" dense hide-details outlined
                                      placeholder="ตำแหน่ง"></v-text-field>
                      </td>
                      <td style="max-width: 150px;">
                        <v-text-field v-model="obj.tel" dense hide-details outlined
                                      placeholder="หมายเลขโทรศัพท์"></v-text-field>
                      </td>
                      <td style="max-width: 95px;">
                        <v-icon
                          @click="data.contact.splice(index, 1); (obj.id!==0)?data.contact_delete.push(obj.id):''">
                          mdi-delete
                        </v-icon>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-btn class="mt-2" @click="addNewContact()">
                  <v-icon class="mr-2">mdi-plus</v-icon>
                  เพิ่ม
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center pb-0 pb-sm-3 text-sm-right justify-sm-end" cols="12" sm="3">
                ละติจูด
              </v-col>
              <v-col cols="12" sm="">
                <v-text-field v-model="data.geom.lat" :rules="[rules.required]" color="search" dense hide-details
                              outlined placeholder="ละติจูด" step="0.001" type="number" @blur="moveMarker"
                              @click="moveMarker"></v-text-field>
              </v-col>
              <v-col class="d-flex align-center pb-0 pb-sm-3 text-sm-right justify-sm-end" cols="12" md="3" sm="2">
                ลองจิจูด
              </v-col>
              <v-col cols="12" sm="">
                <v-text-field v-model="data.geom.lon" :rules="[rules.required]" color="search" dense hide-details
                              outlined placeholder="ลองจิจูด" step="0.001" type="number" @blur="moveMarker"
                              @click="moveMarker"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center pb-0 pb-sm-3 text-sm-right justify-sm-end" cols="12" sm="3">
                จังหวัด
              </v-col>
              <v-col cols="12" sm="">
                <v-autocomplete v-model="selectedProvince" :items="province" dense hide-details item-text="name"
                                item-value="id" outlined></v-autocomplete>
              </v-col>
              <v-col class="d-flex align-center pb-0 pb-sm-3 text-sm-right justify-sm-end" cols="12" sm="1">
                อำเภอ
              </v-col>
              <v-col cols="12" sm="">
                <v-autocomplete v-model="selectedDistrict" :items="forSelectDistrict" dense hide-details
                                item-text="name" item-value="id" outlined></v-autocomplete>
              </v-col>
              <v-col class="d-flex align-center pb-0 pb-sm-3 text-sm-right justify-sm-end" cols="12" sm="1">
                ตำบล
              </v-col>
              <v-col cols="12" sm="">
                <v-autocomplete v-model="selectedSubDistrict" :items="forSelectSubDistrict" dense hide-details
                                item-text="name" item-value="id" outlined></v-autocomplete>
              </v-col>
            </v-row>
            <br/>
            <span class="red--text">*เลื่อนหมุดบนแผนที่เพื่อย้ายโรงงาน</span>
            <div id="mapDialogCompany" class="pt-1" style="height: 250px;"></div>
          </v-form>
        </div>
        <div v-show="isQuerySuccess" class="py-9 success--text align-center justify-center text-center"
             style="font-size: 20px;">
          บันทึกสำเร็จ
        </div>
      </v-card-text>
      <v-card-actions>
        <div class="d-flex flex-wrap justify-end" style="width: 100%">
          <v-btn elevation="0" @click="isOpenDialog=false">
            ปิด
          </v-btn>
          <v-btn v-if="!isQuerySuccess && mode!=='read'" :disabled="isFetching" class="ml-3"
                 color="search-item white--text" elevation="0" @click="confirm()">
            บันทึก
            <v-progress-circular v-show="isFetching" class="ml-3" indeterminate size="16"></v-progress-circular>
          </v-btn>
          <div v-if="errorMessage!==''" class="text-right" style="width: 100%">
            <span class="error--text"> บันทึกไม่สำเร็จ ({{ errorMessage }})</span>
          </div>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import projectConstant from '../../../assets/js/projectConstant'
import ImageGallery from '../../imageGallery'

export default {
  name: 'SettingCompanyListDialog',
  components: { ImageGallery },
  props: {
    isShow: Boolean,
    mode: String, // ['edit', 'add', 'read']
    value: Object
  },
  mounted () {
    this.$store.dispatch('ref/getFactoryType')
    this.$store.dispatch('ref/getPersonType')
    this.$store.dispatch('ref/getIndustryType')
    this.$store.dispatch('ref/getZone')
    this.$store.dispatch('ref/getColony')
    this.$store.dispatch('getProvince')
  },
  data () {
    return {
      data: {
        geom: {},
        type: { id: -1 },
        emailFactory: '',
        emailStaff: '',
        contact: [{ id: 0, name: '', position: '', tel: '' }],
        contact_delete: []
      },
      images: {
        uploadedImages: [],
        tobeUploadImages: [],
        tobeRemoveImages: []
      },
      logo: {
        uploadedImages: [],
        tobeUploadImages: [],
        tobeRemoveImages: []
      },
      selectedGeocode: '',
      selectedSubDistrict: '',
      selectedDistrict: '',
      selectedProvince: '',
      selectedPostcode: '',
      selectedPersonType: '',
      isOpenDialog: false,
      map: null,
      isQuerySuccess: false,
      errorMessage: '',
      isFetching: false,
      markerIcon: require('@/assets/img/marker.png'),
      rules: projectConstant.rules,
      forSelectDistrict: [],
      forSelectSubDistrict: []
    }
  },
  methods: {
    async createLongdoMap () {
      // create longdo map
      this.map = new window.longdo.Map({
        placeholder: document.getElementById('mapDialogCompany')
      })
      try {
        // manage cache longdo map layer
        await (window.longdoJsInterface.Util.cacheLongdoMapLayer(window.longdo, 'diw_front_cache', this.map))
      } catch (e) {
        console.warn('At cacheLongdoMapLayer', e)
      }
      try {
        this.map.Layers.setBase(window.longdo.Layers.POI)
        this.map.enableFilter(window.longdo.Filter.Light)
      } catch (e) {
        console.warn('At [setBase or enableFilter]', e)
      }
      this.map.Event.bind('overlayDrop', () => {
        const markerLocation = this.map.Overlays.list()[0].location()
        if (markerLocation.lat && markerLocation.lon) {
          this.data.geom = markerLocation
          this.markerMoved()
        }
      })
      if (this.$vuetify.breakpoint.xs) {
        this.map.Ui.Zoombar.visible(false)
      }
      // this.map.Ui.LayerSelector.visible(false)
    },
    mapResize () {
      if (this.map) this.map.resize()
    },
    moveMarker () {
      this.map.Overlays.list()[0].move(this.data.geom, true)
      this.map.location(this.data.geom, true)
      this.markerMoved()
    },
    async markerMoved () {
      const address = await this.$store.dispatch('reverseGeocoding', this.data.geom)
      const geocode = address.geocode
      this.selectedGeocode = geocode
      this.selectedProvince = Number(geocode.substr(0, 2))
      this.selectedDistrict = Number(geocode.substr(0, 4))
      this.selectedSubDistrict = Number(geocode.substr(0, 6))
      this.selectedPostcode = address.postcode
    },
    async remount () {
      await this.createLongdoMap()
      let consideredLocation = { lat: 13.721874, lon: 100.530351 }
      if (this.data.geom.lat) {
        consideredLocation = this.data.geom
      }
      this.map.location(consideredLocation)
      this.map.zoom(12)
      this.map.Overlays.clear()
      const marker = new window.longdo.Marker(
        consideredLocation,
        {
          title: this.data.name,
          draggable: (this.mode !== 'read'),
          icon: { url: this.markerIcon }
        }
      )
      this.map.Overlays.add(marker)
    },
    addNewContact () {
      const finalIndex = this.data.contact.length - 1
      const obj = this.data.contact[finalIndex]
      if (this.data.contact.length > 0 && obj.name.trim() === '' && obj.position.trim() === '' && obj.tel.trim() === '') {
      } else {
        this.data.contact.push({ id: 0, name: '', position: '', tel: '' })
      }
    },
    async confirm () {
      if (!this.$refs.logoGallery.isValid() || !this.$refs.imageGallery.isValid()) {
        this.$emit('openSnackbar', 'กรุณาตรวจสอบไฟล์')
        return false
      }
      this.errorMessage = ''
      const isFormValidate = this.$refs.form.validate()
      if (isFormValidate) {
        this.isFetching = true
        const response = await this.doQuery()
        this.isFetching = false
        if (response === true) {
          this.isQuerySuccess = true
          this.$emit('querySuccess', true)
        } else {
          this.errorMessage = response
        }
      }
    },
    async doQuery () {
      this.data.contact = this.data.contact.filter(item => item.name !== '')
      const payload = {
        // id = 0 (if mode is add)
        // id = id (if mode is edit)
        id: (this.mode === 'add') ? 0 : this.data.id,
        uuid: this.uuid || '',
        no: this.data.no,
        noNew: this.data.noNew,
        name: this.data.name,
        address: this.data.address,
        type: (this.data.type.id === -1) ? '' : this.data.type.id,
        geocode: this.selectedGeocode,
        subdistrict: ((this.selectedProvince !== 10) ? 'ต.' : 'แขวง') + (this.forSelectSubDistrict.find(item => item.id === this.selectedSubDistrict) || { name: '' }).name,
        district: ((this.selectedProvince !== 10) ? 'อ.' : 'เขต') + (this.forSelectDistrict.find(item => item.id === this.selectedDistrict) || { name: '' }).name,
        province: ((this.selectedProvince !== 10) ? 'จ.' : '') + (this.province.find(item => item.id === this.selectedProvince) || { name: '' }).name,
        postcode: this.selectedPostcode,
        lat: this.data.geom.lat,
        lon: this.data.geom.lon,
        person_type_id: this.selectedPersonType,
        email_factory: this.data.emailFactory || '',
        email_staff: this.data.emailStaff || '',
        images: this.images.tobeUploadImages,
        images_delete: this.images.tobeRemoveImages,
        contact_id: this.data.contact.map(item => item.id),
        contact_name: this.data.contact.map(item => item.name),
        contact_position: this.data.contact.map(item => item.position),
        contact_tel: this.data.contact.map(item => item.tel),
        contact_delete: this.data.contact_delete,
        type_industry: this.data.type_industry || '',
        zone: (String(this.data.type_industry) === '2') ? this.data.zone : '',
        colony: (String(this.data.type_industry) === '3') ? this.data.colony : ''
      }
      if (this.logo.tobeUploadImages.length >= 1) { // if file
        payload.logo = this.logo.tobeUploadImages[0]
      } else if (this.logo.tobeRemoveImages.length >= 1) {
        payload.logo_delete = 1
      }
      return await this.$store.dispatch('manage/doQueryFactory', payload)
    }
  },
  watch: {
    async value (newVal, oldVal) {
      if (newVal.id) {
        this.data = {
          type: {},
          geom: {}
        }
        const factoryId = newVal.id
        const payload = { uuid: this.uuid, id: factoryId }
        const data = await this.$store.dispatch('manage/getFactoryDetail', payload)
        this.data.id = newVal.id
        this.data.no = data.no
        this.data.noNew = data.noNew
        this.data.name = data.name
        this.data.address = data.address
        this.data.type = (data.type.id) ? data.type : { id: -1, name: 'ไม่มี' }
        this.selectedPersonType = (data.personType) ? data.personType.id : ''
        this.data.emailFactory = data.emailFactory
        this.data.emailStaff = data.emailStaff
        this.data.type_industry = (data.industryType) ? data.industryType.id : null
        this.data.zone = (data.zone) ? data.zone.id : null
        this.data.colony = (data.colonyIndustry) ? data.colonyIndustry.id : null
        const contactKey = Object.keys(data.contact)
        this.data.contact = []
        contactKey.forEach(
          key => {
            if (data.contact[key].name === '') {
              return
            }
            this.data.contact.push(data.contact[key])
          }
        )
        this.data.contact_delete = []
        // this.data.logo = data.logo
        const wrappedDataLogo = { src: data.logo, id: -1 }
        this.logo.uploadedImages = [wrappedDataLogo]
        this.images.uploadedImages = data.images
        const geom = String(data.geom).replace('POINT', '').replace('(', '').replace(')', '')
        const lonlat = geom.split(' ')
        this.data.geom = { lat: parseFloat(lonlat[1]), lon: parseFloat(lonlat[0]) }
        this.selectedProvince = Number(data.provinceCode)
        this.selectedDistrict = Number(data.districtCode)
        this.selectedSubDistrict = Number(data.subdistrictCode)
        this.selectedGeocode = Number(data.subdistrictCode)
        await this.remount()
        this.data = { ...this.data }
      } else {
        this.data = newVal
        this.selectedPersonType = ''
        this.data.contact = [{ id: 0, name: '', position: '', tel: '' }]
        this.data.contact_delete = []
      }
    },
    isShow (newVal, oldVal) {
      if (newVal !== oldVal || newVal === true) {
        this.isOpenDialog = newVal
      }
      if (newVal === true) {
        this.$nextTick(() => {
          this.isQuerySuccess = false
          this.errorMessage = ''
          this.images = {
            uploadedImages: [],
            tobeUploadImages: [],
            tobeRemoveImages: []
          }
          this.logo = {
            uploadedImages: [],
            tobeUploadImages: [],
            tobeRemoveImages: []
          }
          if (this.$refs.form) this.$refs.form.resetValidation()
          if (this.$refs.imageGallery) this.$refs.imageGallery.resetComponent()
        })
        setTimeout(this.remount, 500)
      }
    },
    isOpenDialog (newVal, oldVal) {
      if (newVal !== oldVal || newVal === false) {
        this.$emit('isShowSignal', newVal)
      }
    },
    async selectedProvince () {
      this.forSelectDistrict = await this.$store.dispatch('getDistrict', { id_parent: this.selectedProvince })
    },
    async selectedDistrict () {
      this.forSelectSubDistrict = await this.$store.dispatch('getSubDistrict', { id_parent: this.selectedDistrict })
    }
  },
  computed: {
    ...mapState('ref', ['factoryType', 'personType', 'industryType', 'zone', 'colonyIndustry']),
    ...mapState('user', ['uuid']),
    ...mapState(['province']),
    forSelectFactoryType () {
      return [{ id: -1, name: 'ไม่มี' }, ...this.factoryType]
    }
  }
}
</script>

<style scoped>
::v-deep .row + .row {
  margin-top: 4px !important;
}

::v-deep .v-input--selection-controls {
  margin-top: 0;
}

::v-deep .theme--light.v-label {
  color: black;
  font-size: 14px;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td {
  /*padding: 8px;*/
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td {
  border-left: thin solid rgba(0, 0, 0, 0.12);
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:last-child {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}

::v-deep .v-data-table__wrapper > table > tbody > tr:last-child > td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.hoverClick {
  background-color: #eeeeee;
  border-color: #eeeeee;
}

.hoverClick:hover {
  background-color: #fafafa !important;
  border: 1px solid black;
  cursor: pointer;
}
</style>
