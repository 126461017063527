<template>
  <div style="height: 100%;">
    <v-container class="pb-0" style="height: 100%;">
      <div id="setting-factory-header" class="d-flex flex-wrap pt-2" style="width: 100%;">
        <div class="d-none d-sm-block">
          <span style="font-size: 24px; color: #00236F;">ตั้งค่าโรงงาน</span>
        </div>
        <v-spacer class="d-flex d-sm-none"/>
        <div class="CRUD_box">
          <v-btn v-if="this.permission.includes('manage factory')" :disabled="uuid === ''" class="addButton"
                 @click="addItem()">
            <v-icon>mdi-plus</v-icon>
            เพิ่มโรงงาน/บริษัท
          </v-btn>
          <v-spacer v-if="$vuetify.breakpoint.width > 700"/>
          <div style="max-width: 300px;">
            <v-text-field v-model="keyword" :disabled="uuid === ''" append-icon="mdi-close" dense hide-details outlined
                          placeholder="ค้นหาด้วยชื่อ" prepend-inner-icon="mdi-magnify"
                          @keydown.enter="search()" @click:append="keyword=''"></v-text-field>
          </div>
        </div>
      </div>
      <div :style="`height: calc(100% - ${headerHeight}px);`" class="pt-5">
        <v-data-table id="companyList_dataTable" :headers="permissionHeader" :items="dataList" :items-per-page="999"
                      disable-sort fixed-header hide-default-footer mobile-breakpoint="0" style="height: 100%;">
          <template v-slot:item.name="{ item }">
            <a class="clickText" @click="emitFactory(item)">{{ item.name }}</a>
          </template>
          <template v-slot:item.type="{ item }">
            {{ item.type.name }}
          </template>
          <template v-slot:item.geom="{ item }">
            {{ item.geom.lat.toFixed(4) + ', ' + item.geom.lon.toFixed(4) }}
          </template>
          <template v-slot:item.visible="{ item }">
            <div v-if="permission.includes('manage factory')" class="d-flex align-center justify-space-between">
              <v-select v-model="item.visible" :items="[{id:true,name:'แสดง'},{id:false,name:'ซ่อน'}]"
                        background-color="white" dense hide-details item-text="name" item-value="id"
                        outlined/>
              <v-icon v-if="item.visible !== item.oldVisible" :class="(item.loading)?'mdi-spin':''" class="ml-2"
                      @click="saveVisible(item)">
                {{ (item.loading) ? 'mdi-loading' : 'mdi-content-save' }}
              </v-icon>
            </div>
            <div v-else>
              {{ (item.visible) ? 'แสดง' : 'ซ่อน' }}
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon v-if="permission.includes('view factory info') && !permission.includes('manage factory')"
                    class="mr-2" @click="viewItem(item)">
              mdi-magnify
            </v-icon>
            <v-icon v-if="permission.includes('manage factory')" class="mr-2 myPencil" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon v-if="permission.includes('manage factory')" class="myTrash" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <div class="d-flex align-center justify-center" style="height: 48px;">
              <span v-if="uuid === ''" class="grey--text">
                กรุณาเข้าสู่ระบบเพื่อใช้งาน
              </span>
              <span v-else>ไม่พบข้อมูล</span>
            </div>
          </template>
        </v-data-table>
      </div>
    </v-container>
    <setting-company-list-dialog v-model="dialogItem" :isShow="openDialog" :mode="dialogMode"
                                 v-on:isShowSignal="handleDialogSignal"
                                 v-on:openSnackbar="openSnackbar"
                                 v-on:querySuccess="handleQuerySuccess"/>
    <v-dialog v-model="openDeleteDialog" max-width="600">
      <v-card>
        <v-card-title>
          <v-spacer/>
          <v-icon @click="openDeleteDialog=false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pa-0">
          <div class="py-9 black--text text-center" style="font-size: 20px;">
            <span v-if="!isFinishDelete && !isFailDelete">
              ยืนยันการลบ "<span class="error--text">{{ toDeleteFactoryName }}</span>"
            </span>
            <span v-else-if="isFinishDelete" class="success--text">ลบโรงงานสำเร็จ</span>
            <span v-else-if="isFailDelete" class="warning--text">ลบโรงงานไม่สำเร็จ</span>
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="d-flex justify-end" style="width: 100%">
            <v-btn elevation="0" @click="openDeleteDialog=false">
              ปิด
            </v-btn>
            <v-btn v-if="!isFinishDelete && !isFailDelete" class="ml-3" color="search-item white--text" elevation="0"
                   @click="confirmDelete()">
              ยืนยัน
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackbar=false">
          ปิด
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import SettingCompanyListDialog from '../dialog/setting/CompanyList'
import { mapState } from 'vuex'

export default {
  name: 'CompanyListSetting',
  components: { SettingCompanyListDialog },
  async mounted () {
    this.reCalculateHeight()
    window.addEventListener('resize', this.reCalculateHeight)

    const uuid = this.uuid || ''
    const payload = { uuid: uuid, keyword: '', page: 1 }
    const data = await this.$store.dispatch('manage/getFactoryList', payload)
    this.dataList = data.items.map(item => {
      item.oldVisible = item.visible
      return item
    })
    this.hasNextPage = data.hasNextPage

    const dom = document.getElementById('companyList_dataTable')
    const target = dom.getElementsByClassName('v-data-table__wrapper')[0]
    target.addEventListener('scroll', this.onFactoryListScroll, { passive: true })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.reCalculateHeight)
  },
  data () {
    return {
      headerHeight: 40,
      keyword: '',
      page: 1,
      hasNextPage: true,
      isFetching: false,
      headers: [
        {
          text: 'ชื่อโรงงาน/บริษัท',
          sortable: false,
          value: 'name'
        },
        {
          text: 'เลขทะเบียนโรงงาน (ใหม่)',
          value: 'noNew',
          sortable: false
        },
        {
          text: 'เลขทะเบียนโรงงาน (เก่า)',
          value: 'no',
          sortable: false
        },
        {
          text: 'ประเภทอุตสาหกรรม',
          value: 'type',
          sortable: false
        },
        {
          text: 'ที่ตั้ง',
          sortable: false,
          value: 'address'
        }
      ],
      dataList: [],
      dialogItem: {},
      openDialog: false,
      toDeleteFactoryName: '',
      toDeleteFactoryId: '',
      openDeleteDialog: false,
      isFinishDelete: false,
      isFailDelete: false,
      dialogMode: '',
      snackbar: false,
      snackbarText: ''
    }
  },
  methods: {
    reCalculateHeight () {
      const dom = document.getElementById('setting-factory-header')
      if (dom) {
        this.headerHeight = dom.offsetHeight
      }
    },
    emitFactory (factory) {
      this.$emit('selectFactory', JSON.parse(JSON.stringify(factory)))
    },
    async saveVisible (item) {
      if (item.oldVisible === item.visible) {
        console.log('same visible as old one reject tx')
      }
      const payload = { uuid: this.uuid, id: item.id, visible: (item.visible) ? 1 : 0 }
      item.loading = true
      const result = await this.$store.dispatch('manage/setFactoryVisible', payload)
      item.loading = undefined
      if (result) {
        item.oldVisible = item.visible
        this.snackbarText = 'บันทึกเรียบร้อย'
        this.snackbar = true
      } else {
        this.snackbarText = 'บันทึกไม่เรียบร้อย'
        this.snackbar = true
      }
    },
    addItem () {
      this.dialogItem = {
        geom: { lat: '', lon: '' },
        type: { id: -1 }
      } // if not add this line vue will not update lat-lon in dialog
      this.openDialog = true
      this.dialogMode = 'add'
    },
    openSnackbar (text) {
      this.snackbarText = text
      this.snackbar = true
    },
    viewItem (item) {
      this.dialogItem = { ...item }
      this.openDialog = true
      this.dialogMode = 'read'
    },
    editItem (item) {
      this.dialogItem = { ...item }
      this.openDialog = true
      this.dialogMode = 'edit'
    },
    deleteItem (item) {
      this.toDeleteFactoryName = item.name
      this.toDeleteFactoryId = item.id
      this.openDeleteDialog = true
      this.isFinishDelete = false
      this.isFailDelete = false
    },
    async confirmDelete () {
      const payload = { id: this.toDeleteFactoryId, uuid: this.uuid || '' }
      const isSuccess = await this.$store.dispatch('manage/doDeleteFactory', payload)
      if (isSuccess) {
        this.isFinishDelete = true
        await this.reloadPage()
      } else {
        this.isFailDelete = true
      }
    },
    handleDialogSignal (e) {
      if (e !== this.exportDialog) {
        this.openDialog = e
      }
    },
    handleQuerySuccess () {
      this.reloadPage()
    },
    async search () {
      this.isFetching = true
      this.page = 1
      const uuid = this.uuid || ''
      const payload = { uuid: uuid, keyword: this.keyword, page: this.page }
      const data = await this.$store.dispatch('manage/getFactoryList', payload)
      this.dataList = [...data.items].map(item => {
        item.oldVisible = item.visible
        return item
      })
      this.hasNextPage = data.hasNextPage
      this.isFetching = false
    },
    async get () {
      this.isFetching = true
      this.page += 1
      const uuid = this.uuid || ''
      const payload = { uuid: uuid, keyword: this.keyword, page: this.page }
      const data = await this.$store.dispatch('manage/getFactoryList', payload)
      this.hasNextPage = data.hasNextPage
      const items = data.items.map(item => {
        item.oldVisible = item.visible
        return item
      })
      this.dataList = [...this.dataList, ...items]
      this.isFetching = false
    },
    async reloadPage () {
      this.isFetching = true
      const uuid = this.uuid || ''
      const payload = { uuid: uuid, keyword: this.keyword, page: this.page }
      const data = await this.$store.dispatch('manage/getFactoryList', payload)
      this.hasNextPage = data.hasNextPage
      this.dataList = [...data.items].map(item => {
        item.oldVisible = item.visible
        return item
      })
      this.isFetching = false
    },
    onFactoryListScroll ({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight * 0.9 && !this.isFetching && this.hasNextPage) {
        this.get()
      }
    }
  },
  watch: {
    uuid (to, from) {
      if (to.length === 0 || to === '') {
        return null
      }
      this.search()
    }
  },
  computed: {
    ...mapState('user', ['uuid', 'permission']),
    permissionHeader () {
      let extendHeader = []
      if (this.permission.includes('manage factory') || this.permission.includes('view factory info')) {
        const widthV = (this.permission.includes('manage factory')) ? 170 : 95
        extendHeader = [...extendHeader, { text: 'แสดง/ซ่อน', sortable: false, value: 'visible', width: widthV }]
        const width = (this.permission.includes('manage factory')) ? 95 : 50
        extendHeader = [...extendHeader, { text: '', sortable: false, value: 'actions', width: width }]
      }
      return [...this.headers, ...extendHeader]
    }
  }
}
</script>

<style scoped>
.myPencil:hover {
  color: #ffc700;
}

.myTrash:hover {
  color: #bf1c12;
}

@media (max-width: 600px) {
  .CRUD_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 300px;
  }

  .addButton {
    margin: 0 0 12px 0;
  }
}

@media (min-width: 600px) {
  .CRUD_box {
    display: flex;
    width: calc(100% - 135px);
  }

  .addButton {
    margin: 0 24px 0 24px;
  }
}

@media (min-width: 960px) and (max-width: 1264px) {
  ::v-deep .container {
    max-width: 960px;
  }
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  /*height: unset;*/
  min-height: 48px;
  padding: 4px 16px;
}

::v-deep .v-data-table__wrapper {
  /*height:calc(100% - 150px) !important;*/
  max-height: 100%;
  overflow-y: unset;
}

::v-deep .v-data-table-header > tr > th {
  background-color: #D8D8D8 !important;
  padding: 0 8px !important;
  color: black;
}

::v-deep .v-data-table-header > tr > th {
  border-left: thin solid rgba(0, 0, 0, 0.3) !important;
  border-top: thin solid rgba(0, 0, 0, 0.3) !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table-header > tr > th:last-child {
  border-right: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:first-child > td {
  border-top: unset;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:first-child {
  border-left: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:last-child {
  border-right: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:last-child > td {
  border-bottom: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:not(:first-child) {
  border-left: thin solid rgba(0, 0, 0, 0.12) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:hover {
  background-color: #e5eeff !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:nth-child(odd) {
  background-color: #F6F6F6;
}
</style>
