<template>
  <div class="fill-height">
    <div class="d-block d-sm-none">
      <v-btn :ripple="false" class="pl-0" color="white" elevation="0" large
             @click="$emit('openMenu', true)">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </div>
    <div class="componentHeight">
      <v-tabs v-model="tab" :show-arrows="$vuetify.breakpoint.xs" background-color="primary" centered grow hide-slider>
        <v-tab v-for="item in filteredTabs" :key="item.text" :disabled="item.disabled" class="white--text">
          {{ item.text }}
          <a :href="getHref(item.ref)" class="a_forRightClick" @click.prevent="changeQuery(item.ref)"></a>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="contentItem" touchless>
        <v-tab-item v-for="item in filteredTabs" :key="item.text">
          <div style="height: 100%">
            <component :is="item.component" :ref="item.ref" :initialFactory="selectedFactory"
                       v-on:selectFactory="selectFactory"></component>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar=false">
          ปิด
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import CompanyListSetting from '../components/setting/CompanyList'
import PollutionPointSetting from '../components/setting/PollutionPoint'
import InstrumentInformation from '../components/setting/InstrumentInformation'
import VerificationFactor from '../components/setting/VerificationFactor'
import { mapState } from 'vuex'

export default {
  name: 'Setting',
  components: {
    // MonitorFactor,
    InstrumentInformation,
    PollutionPointSetting,
    CompanyListSetting,
    VerificationFactor
  },
  beforeMount () {
    const ref = this.$route.query.reference
    const refId = parseInt(this.$route.query.refId)
    const refName = this.$route.query.refName
    if (ref && refId) {
      this.selectedFactory.id = refId
      this.selectedFactory.name = refName
      this.tab = this.tabsData.findIndex(item => item.ref === ref)
    }
  },
  data () {
    return {
      snackbar: false,
      snackbarText: '',
      selectedFactory: {
        id: null
      },
      tab: 0,
      tabsData: [
        {
          text: 'โรงงาน',
          component: CompanyListSetting,
          ref: 'cl',
          require: 'view factory info',
          disabled: false
        },
        {
          text: 'จุดระบายมลพิษ/จุดตรวจวัด',
          component: PollutionPointSetting,
          ref: 'pp',
          require: 'view measurement info',
          disabled: true
        },
        {
          text: 'ปัจจัยการตรวจสอบ',
          component: VerificationFactor,
          ref: 'vf',
          require: 'view instrument info',
          disabled: true
        },
        {
          text: 'อุปกรณ์เก็บบันทึกข้อมูล',
          component: InstrumentInformation,
          ref: 'ii',
          require: 'view device info',
          disabled: true
        }
      ]
    }
  },
  methods: {
    selectFactory (factory) {
      this.selectedFactory = factory
      this.snackbarText = `เลือกโรงงาน ${factory.name}`
      this.snackbar = true
    },
    changeQuery (reference) {
      const payload = this.getHrefPayload(reference)
      this.$router.push({ name: this.$route.name, query: payload })
        .catch(() => {}) // remove error from console log // this error mostly appear when navigate on same route
    },
    getHrefPayload (ref) {
      const payload = { reference: ref }
      if (this.selectedFactory.id) payload.refId = this.selectedFactory.id
      if (this.selectedFactory.name) payload.refName = this.selectedFactory.name
      return payload
    },
    getHref (ref) {
      const payload = this.getHrefPayload(ref)
      return `?${new URLSearchParams(payload).toString()}`
    }
  },
  watch: {
    tab (newVal, oldVal) {
      if (this.$refs.pp && newVal === 1) {
        setTimeout(this.$refs.pp.resizeEvent, 500)
      }
    },
    snackbar (to, from) {
      if (!to) {
        this.snackbarText = ''
      }
    }
  },
  computed: {
    ...mapState('user', ['permission']),
    filteredTabs () {
      const disabledTabs = this.tabsData.map(item => ({ ...item, disabled: item.disabled && !this.selectedFactory.id }))
      return disabledTabs.filter(item => this.permission.includes(item.require) || item.require === '')
    }
  }
}
</script>

<style scoped>

@media (max-width: 600px) {
  .componentHeight {
    height: calc(100% - 44px);
  }
}

@media (min-width: 600px) {
  .componentHeight {
    height: 100%;
  }
}

::v-deep .v-slide-group__next > i {
  color: white !important;
}

::v-deep .v-slide-group__next > .theme--light.v-icon.v-icon.v-icon--disabled {
  color: rgba(0, 0, 0, 0) !important;
}

::v-deep .v-slide-group__prev > i {
  color: white !important;
}

::v-deep .v-slide-group__prev > .theme--light.v-icon.v-icon.v-icon--disabled {
  color: rgba(0, 0, 0, 0) !important;
}

.contentItem {
  height: calc(100% - 48px);
}

::v-deep .v-window__container, .v-window-item {
  height: 100%;
}

::v-deep .v-tab--active {
  background: rgb(158, 137, 77);
  background: -moz-linear-gradient(90deg, rgba(158, 137, 77, 1) 0%, rgba(183, 166, 118, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(158, 137, 77, 1) 0%, rgba(183, 166, 118, 1) 100%);
  background: linear-gradient(90deg, rgba(158, 137, 77, 1) 0%, rgba(183, 166, 118, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9e894d", endColorstr="#b7a676", GradientType=1);
}
</style>
