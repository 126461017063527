<template>
  <v-dialog v-model="isOpenDialog" :max-width="(!querySuccess) ? 1200 : 600" :scrollable="!$vuetify.breakpoint.xs">
    <v-card>
      <v-card-title v-if="!querySuccess">
        <span class="secondary--text">ปัจจัยการตรวจสอบ</span>
        <v-spacer/>
        <v-icon @click="isOpenDialog=false">mdi-close</v-icon>
      </v-card-title>
      <v-card-title v-else>
        <v-spacer/>
        <v-icon @click="isOpenDialog=false">mdi-close</v-icon>
      </v-card-title>
      <v-divider v-if="!querySuccess"/>
      <v-card-text class="pa-0">
        <div v-show="!querySuccess" class="py-4 px-4 px-sm-8 black--text">
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col class="d-flex align-center justify-sm-end text-sm-right pb-0 pb-sm-3" cols="12" sm="2">
                ยี่ห้อ
              </v-col>
              <v-col cols="">
                <v-text-field v-model="brand" :readonly="mode==='view'" dense hide-details outlined></v-text-field>
              </v-col>
              <v-col class="d-flex align-center justify-sm-end text-sm-right pb-0 pb-sm-3" cols="12" sm="2">
                รุ่น
              </v-col>
              <v-col cols="">
                <v-text-field v-model="model" :readonly="mode==='view'" dense hide-details outlined></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center justify-sm-end text-sm-right pb-0 pb-sm-3" cols="12" sm="2">
                ผู้ผลิต
              </v-col>
              <v-col cols="">
                <v-text-field v-model="manufacturer" :readonly="mode==='view'" dense hide-details
                              outlined></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center justify-sm-end text-sm-right pb-0 pb-sm-3" cols="12" sm="2">
                เทคนิคการตรวจวัด
              </v-col>
              <v-col cols="">
                <v-text-field v-model="technical" :readonly="mode==='view'" dense hide-details outlined></v-text-field>
              </v-col>
              <v-col class="d-flex align-center justify-sm-end text-sm-right pb-0 pb-sm-3" cols="12" sm="2">
                โรงงานที่ติดตั้ง
              </v-col>
              <v-col cols="">
                <v-autocomplete v-model="data.factory.id" :items="factory" :rules="[rules.required]" color="search"
                                dense hide-details item-text="name" item-value="id" outlined readonly></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center justify-sm-end text-sm-right pb-0 pb-sm-3" cols="12" sm="2">
                พารามิเตอร์ที่ตรวจวัด
              </v-col>
              <v-col cols="">
                <v-autocomplete v-model="data.parameter.id" :items="parameterList" :readonly="mode==='view'"
                                :rules="[rules.required]" color="search" dense hide-details item-color="search"
                                item-text="name" item-value="id" outlined placeholder="--เลือก--"></v-autocomplete>
              </v-col>
              <v-col class="d-flex align-center justify-sm-end text-sm-right pb-0 pb-sm-3" cols="12" sm="2">
                จุดตรวจวัด
              </v-col>
              <v-col cols="">
                <v-autocomplete v-model="data.measurement.id"
                                :background-color="!data.factory.id ? 'grey lighten-3' : 'white'"
                                :disabled="!data.factory.id" :items="factorySensor"
                                :readonly="!data.factory.id || mode==='view'" :rules="[rules.required]" color="search"
                                dense hide-details item-text="name" item-value="id" outlined></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <div>
                  ข้อมลการบำรุงรักษา / แผนการบำรุงรักษา
                  <v-textarea v-model="maintenance" :readonly="mode==='view'" class="mt-2" hide-details outlined
                              placeholder="โปรดระบุ" rows="5"></v-textarea>
                </div>
                <div class="d-flex align-center pt-4">
                  <div class="pr-3">
                    ช่วงการตรวจวัด
                  </div>
                  <v-text-field v-model="data.minimumRange" :readonly="mode==='view'" :rules="[rules.required]" dense
                                hide-details outlined style="max-width: 100px;" type="number"></v-text-field>
                  <div class="px-3">
                    ถึง
                  </div>
                  <v-text-field v-model="data.maximumRange" :readonly="mode==='view'" :rules="[rules.required]" dense
                                hide-details outlined style="max-width: 100px;" type="number"></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox v-model="data.ignore_condition" class="mt-0" dense hide-details
                            style="margin-bottom: 2px; width: fit-content;"
                            @change="data = { ...data }">
                  <template v-slot:label>
                    <span class="black--text" style="font-size: 14px;">พารามิเตอร์ไม่มีค่ามาตรฐาน</span>
                  </template>
                </v-checkbox>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr class="primary">
                      <th class="text-center white--text" scope="col">MIN</th>
                      <th class="text-center white--text" scope="col" style="min-width: 145px;">เกณฑ์มลพิษ</th>
                      <th class="text-center white--text" scope="col">MAX</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td class="tableLeft">
                        <v-text-field v-model="data.conditionNormal.leftValue" :disabled="ignore_condition"
                                      :readonly="mode==='view'" :rules="(data.ignore_condition) ? [] :[rules.required]"
                                      class="myTextField" dense hide-details outlined type="number"></v-text-field>
                      </td>
                      <td class="text-center">
                        &#8804; <span class="chip success white--text mx-2" style="max-width: 85px;">ปกติ</span> &#8804;
                      </td>
                      <td class="tableRight">
                        <v-text-field v-model="data.conditionNormal.rightValue" :disabled="ignore_condition"
                                      :readonly="mode==='view'" :rules="(data.ignore_condition) ? [] :[rules.required]"
                                      class="myTextField" dense hide-details outlined type="number"></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="tableLeft">
                        <v-text-field v-model="data.conditionWarning.leftValue" :disabled="ignore_condition"
                                      :readonly="mode==='view'" :rules="(data.ignore_condition) ? [] :[rules.required]"
                                      class="myTextField" dense hide-details outlined type="number"></v-text-field>
                      </td>
                      <td class="text-center">
                        &#60; <span class="chip warning white--text mx-2" style="max-width: 85px;">เฝ้าระวัง</span>
                        &#8804;
                      </td>
                      <td class="tableRight">
                        <v-text-field v-model="data.conditionWarning.rightValue" :disabled="ignore_condition"
                                      :readonly="mode==='view'" :rules="(data.ignore_condition) ? [] :[rules.required]"
                                      class="myTextField" dense hide-details outlined type="number"></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="tableLeft tableLast">
                        <v-text-field v-model="data.conditionSevere.leftValue" :disabled="ignore_condition"
                                      :readonly="mode==='view'" :rules="(data.ignore_condition) ? [] :[rules.required]"
                                      class="myTextField" dense hide-details outlined type="number"></v-text-field>
                      </td>
                      <td class="text-center tableLast">
                        &#60;
                        <span class="chip error white--text mx-2" style="max-width: 85px;">
                          แจ้งเตือน
                        </span>
                        &#60;
                      </td>
                      <td class="tableRight tableLast">
<!--                        <v-text-field v-model="data.conditionSevere.rightValue" :readonly="mode==='view'"-->
<!--                                      :disabled="ignore_condition"-->
<!--                                      :rules="[rules.required]" class="myTextField" dense hide-details outlined-->
<!--                                      type="number"></v-text-field>-->
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <div class="d-flex mt-4">
                  <div class="pr-3 pt-3">
                    ค่ามาตรฐาน
                  </div>
                  <div>
                    <v-text-field v-model="data.stdValue" :disabled="ignore_condition"
                                  :readonly="mode==='view'"
                                  :rules="(data.ignore_condition) ? [] :[rules.required]" dense outlined
                                  type="number"></v-text-field>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <div v-show="querySuccess" class="py-9 success--text align-center justify-center text-center"
             style="font-size: 20px;">
          บันทึกสำเร็จ
        </div>
      </v-card-text>
      <v-card-actions>
        <div class="d-flex flex-wrap justify-end pb-1" style="width: 100%">
          <v-btn elevation="0" @click="isOpenDialog=false">
            ปิด
          </v-btn>
          <v-btn v-if="!querySuccess && mode!=='view'" :disabled="isFetch" class="ml-3"
                 color="search-item white--text" elevation="0" @click="confirm()">
            บันทึก
            <v-progress-circular v-show="isFetch" class="ml-3" indeterminate size="16"></v-progress-circular>
          </v-btn>
          <div v-if="errorMessage!==''" class="text-right" style="width: 100%">
            <span class="error--text"> บันทึกไม่สำเร็จ ({{ errorMessage }})</span>
          </div>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import projectConstant from '../../../assets/js/projectConstant'

export default {
  name: 'FactorDialog',
  props: ['isShow', 'value', 'mode', 'initialFactory'], // mode is [ 'view', 'add', 'edit' ]
  mounted () {
    if (this.initialFactory && this.mode === 'add') {
      this.data.factory.id = this.initialFactory.id
      this.data.factory.name = this.initialFactory.name
      this.data = { ...this.data }
    }
    this.$store.dispatch('ref/getParameterList') // no wait this async
    this.$store.dispatch('ref/getFactoryAll').then(
      (data) => {
        this.factory = data
      }
    )
  },
  data () {
    return {
      factory: [],
      isOpenDialog: false,
      factorySensor: [],
      brand: '',
      model: '',
      manufacturer: '',
      technical: '',
      maintenance: '',
      data: {
        factory: { id: 0 },
        parameter: {},
        measurement: {},
        conditionNormal: { leftValue: '', rightValue: '' },
        conditionWarning: { leftValue: '', rightValue: '' },
        conditionSevere: { leftValue: '', rightValue: '' },
        ignore_condition: false
      },
      rules: projectConstant.rules,
      querySuccess: false,
      queryFail: false,
      isFetch: false,
      errorMessage: ''
    }
  },
  methods: {
    standardValueRule (value) {
      const valueList = [
        this.data.conditionNormal.leftValue,
        this.data.conditionNormal.rightValue,
        this.data.conditionWarning.leftValue,
        this.data.conditionWarning.rightValue,
        this.data.conditionSevere.leftValue,
        this.data.conditionSevere.rightValue
      ]
      let isValid = true
      for (const value of valueList) {
        if (parseFloat(value) > parseFloat(this.data.stdValue)) {
          isValid = false
          break
        }
      }
      return isValid || 'มีบางค่าในตารางที่สูงกว่าค่ามาตรฐาน'
    },
    preparePayload () {
      const payload = {
        // required-key
        id: this.data.id || 0,
        uuid: this.uuid,
        parameter: this.data.parameter.id,
        measurement: this.data.measurement.id,
        left_value_1: this.data.conditionNormal.leftValue,
        right_value_1: this.data.conditionNormal.rightValue,
        left_value_2: this.data.conditionWarning.leftValue,
        right_value_2: this.data.conditionWarning.rightValue,
        left_value_3: this.data.conditionSevere.leftValue,
        right_value_3: this.data.conditionSevere.rightValue,
        std_value: this.data.stdValue,
        // after this line is non-required key
        brand: this.brand,
        model: this.model,
        manufacturer: this.manufacturer,
        technical: this.technical,
        maintenance: this.maintenance,
        ignore_condition: (this.data.ignore_condition) ? 1 : 0,
        // not yet designed key
        minimumRange: this.data.minimumRange || 0,
        maximumRange: this.data.maximumRange || 0
      }
      return payload
    },
    async confirm () {
      const isValid = this.$refs.form.validate()
      if (!isValid) {
        return false
      }
      const payload = this.preparePayload()
      this.isFetch = true
      this.errorMessage = ''
      const result = await this.$store.dispatch('manage/doQueryInstrument', payload)
      this.isFetch = false
      if (result === true) {
        this.$emit('querySuccessSignal', true)
        this.querySuccess = true
      } else {
        this.errorMessage = result
        this.queryFail = true
      }
    }
  },
  watch: {
    isShow (newVal, oldVal) {
      if (newVal !== oldVal || newVal === true) {
        this.data = {
          factory: { id: 0 },
          parameter: {},
          measurement: {},
          conditionNormal: {},
          conditionWarning: {},
          conditionSevere: {}
        }
        this.isOpenDialog = newVal
      }
      if (newVal === true) {
        this.querySuccess = false
        this.queryFail = false
        this.errorMessage = ''
      }
      this.$nextTick(() => {
        if (this.initialFactory && this.mode === 'add') {
          this.data.factory.id = this.initialFactory.id
          this.data.factory.name = this.initialFactory.name
          this.data = { ...this.data }
        }
      })
    },
    async value (newVal, oldVal) {
      this.data = newVal
      if (this.data.id) {
        const fetched = await this.$store.dispatch('manage/getInstrument', { id: this.data.id, uuid: this.uuid })
        this.brand = fetched.brand || ''
        this.model = fetched.model || ''
        this.manufacturer = fetched.manufacturer || ''
        this.technical = fetched.technical || ''
        this.maintenance = fetched.maintenance || ''
        this.data.minimumRange = fetched.minimumRange || 0
        this.data.maximumRange = fetched.maximumRange || 0
        this.data.ignore_condition = !!fetched.ignoreCondition
        this.data = { ...this.data } // tell vue to update component
      } else {
        if (this.$refs.form) this.$refs.form.resetValidation()
      }
      const factoryId = this.data.factory.id || null
      if (factoryId) {
        this.factorySensor = await this.$store.dispatch('ref/measurement', { id_parent: factoryId })
      }
    },
    isOpenDialog (newVal, oldVal) {
      if (newVal !== oldVal || newVal === false) {
        this.$emit('isShowSignal', newVal)
      }
      if (this.initialFactory && this.mode === 'add') {
        this.data.factory.id = this.initialFactory.id
        this.data.factory.name = this.initialFactory.name
        this.data = { ...this.data }
      }
    },
    async 'data.factory.id' (to, from) {
      if (to) {
        this.factorySensor = await this.$store.dispatch('ref/measurement', { id_parent: to })
      }
    },
    'data.conditionNormal.rightValue' (to, from) {
      if (to !== from && to !== this.data.conditionWarning.leftValue) {
        this.data.conditionWarning.leftValue = to
        this.data = { ...this.data }
      }
    },
    'data.conditionWarning.leftValue' (to, from) {
      if (to !== from && to !== this.data.conditionNormal.rightValue) {
        this.data.conditionNormal.rightValue = to
        this.data = { ...this.data }
      }
    },
    'data.conditionWarning.rightValue' (to, from) {
      if (to !== from) {
        this.data.conditionSevere.leftValue = to
        this.data.stdValue = to
        this.data = { ...this.data }
      }
    },
    'data.conditionSevere.leftValue' (to, from) {
      if (to !== from) {
        this.data.conditionWarning.rightValue = to
        this.data.stdValue = to
        this.data = { ...this.data }
      }
    },
    'data.stdValue' (to, from) {
      if (to !== from) {
        this.data.conditionWarning.rightValue = to
        this.data.conditionSevere.leftValue = to
        this.data = { ...this.data }
      }
    }
  },
  computed: {
    ...mapState('user', ['uuid']),
    ...mapState('ref', ['parameterList']),
    ignore_condition () {
      return this.data.ignore_condition
    }
  }
}
</script>

<style scoped>
.myTextField {
  width: 80px;
  margin: 0 auto;
}

.chip {
  padding: 2px 8px;
  border-radius: 24px !important;
}

.tableLeft {
  border-left: thin solid rgba(0, 0, 0, 0.12);
  min-width: 100px;
}

.tableRight {
  border-right: thin solid rgba(0, 0, 0, 0.12);
  min-width: 100px;
}

.tableLast {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

::v-deep .row + .row {
  margin-top: 9px !important;
}

::v-deep .myTextField.v-text-field--outlined > .v-input__control > .v-input__slot {
  height: unset;
}

::v-deep .myTextField.v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 30px;
}

</style>
