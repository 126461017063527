<template>
  <div class="view">
    <div class="leftPanel">
      <div class="pt-2 pb-1 pl-3 d-none d-sm-block">
        <span style="font-size: 24px; color: #00236F;">
          ตั้งค่าจุดระบายมลพิษ/จุดตรวจวัด
        </span>
      </div>
      <v-tabs-items v-model="detailTab" class="detailTab pt-2 pt-sm-0" style="width: 100%;">
        <v-tab-item style="height: 100%;">
<!--          <div class="pb-2 pt-2">-->
<!--            <div class="d-flex py-2 px-0 px-3" style="width: 100%; background-color: rgba(65, 149, 24, 0.2);">-->
<!--              <div class="d-flex align-center justify-sm-center pr-2 search&#45;&#45;text">-->
<!--                ประเภท-->
<!--              </div>-->
<!--              <div class="pr-2">-->
<!--                <v-select v-model="filterType" :items="filterOption" background-color="white" :disabled="isFetchingSensor"-->
<!--                          color="search" dense hide-details item-color="search-item" outlined placeholder="ทั้งหมด"-->
<!--                          style="max-width: 150px;"></v-select>-->
<!--              </div>-->
<!--              <v-text-field v-model="keyword" append-icon="mdi-close" background-color="white" color="search" dense-->
<!--                            hide-details outlined placeholder="ค้นหาด้วยชื่อจุดตรวจวัด หรือยี่ห้อเครื่องตรวจวัด" :disabled="isFetchingSensor"-->
<!--                            prepend-inner-icon="mdi-magnify" @keydown.enter="enterSearch()" @click:append="keyword=''"/>-->
<!--            </div>-->
<!--          </div>-->
          <div class="sensorListContainer"
               @scroll="onSensorListScroll">
            <div v-for="(item, index) in sensorData" :key="index" class="searchListRow px-3">
              <company-list-item :id="item.id"
                                 :address="item.address"
                                 :cemsCount="item.countCems"
                                 :distance="item.distance"
                                 :isBookmarked="item.isBookmarked"
                                 :isGoldenChip="true"
                                 :isShowBookmark="false"
                                 :isShowChevron="true"
                                 :isShowChip="true"
                                 :lat="item.lat"
                                 :logo="item.logo"
                                 :lon="item.lon"
                                 :mobileCount="item.countMobile"
                                 :name="item.name"
                                 :no="item.noNew"
                                 :opmsCount="item.countOpms"
                                 :severityCems="item.severityCems"
                                 :severityOpms="item.severityOpms"
                                 :severityStation="item.severityStation"
                                 :stationCount="item.countStation"
                                 class="pt-2 pb-2 pl-1"
                                 v-on:clickChevronRight="goToDetail(item)"
                                 v-on:clickLabel="moveMapToLocation">
              </company-list-item>
              <v-divider class="d-none d-sm-block"/>
            </div>
            <div v-show="this.factoryHasNextPage || isFetchingSensor" style="height: 64px;">
              <v-row align="center" class="fill-height ma-0" justify="center">
                <v-progress-circular color="mb-3" indeterminate></v-progress-circular>
              </v-row>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item style="height: 100%;">
          <div class="d-flex grey lighten-4 mb-2 mt-2" style="width: 100%;">
            <div class="align-self-center pl-3">
              <v-icon @click="detailTab=0">
                mdi-chevron-left
              </v-icon>
            </div>
            <company-list-item :id="selectedSensor.id"
                               :address="selectedSensor.address"
                               :isShowChip="true"
                               :logo="selectedSensor.logo"
                               :name="selectedSensor.name"
                               :no="selectedSensor.noNew"
                               :severityCems="selectedSensor.severityCems"
                               :severityOpms="selectedSensor.severityOpms"
                               :severityStation="selectedSensor.severityStation"
                               class="pt-2 pb-2 pl-1" style="width: calc(100% - 36px);">
            </company-list-item>
          </div>
          <div class="pa-3" style="overflow-y: auto; height: calc(100% - 100px);">
            <div v-if="permission.includes('manage measurement')" class="pb-3">
              <v-btn @click="openAdd">
                <v-icon>mdi-plus</v-icon>
                เพิ่มจุดระบายมลพิษ/จุดตรวจวัด
              </v-btn>
            </div>
            <div v-if="selectedSensor.countCems > 0" class="pb-4">
              <div class="primary--text" style="font-size: 24px;">
                CEMS
              </div>
              <div class="mb-2">
                <measurement-table :sensorData="cemsData"
                                   :showAction="permission.includes('manage measurement') || permission.includes('view measurement info')"
                                   type="cems" v-on:deleteBtn="openDelete" v-on:editBtn="openEdit"
                                   v-on:viewBtn="openView"/>
              </div>
            </div>
            <div v-if="selectedSensor.countOpms > 0" class="pb-4">
              <div class="primary--text" style="font-size: 24px;">
                WPMS
              </div>
              <div>
                <measurement-table :sensorData="opmsData"
                                   :showAction="permission.includes('manage measurement') || permission.includes('view measurement info')"
                                   type="opms" v-on:deleteBtn="openDelete" v-on:editBtn="openEdit"
                                   v-on:viewBtn="openView"/>
              </div>
            </div>
            <div v-if="selectedSensor.countMobile > 0" class="pb-3">
              <div class="primary--text" style="font-size: 24px;">
                MOBILE
              </div>
              <div>
                <measurement-table :sensorData="mobileData"
                                   :showAction="permission.includes('manage measurement') || permission.includes('view measurement info')"
                                   type="mobile" v-on:deleteBtn="openDelete" v-on:editBtn="openEdit"
                                   v-on:viewBtn="openView"/>
              </div>
            </div>
            <div v-if="selectedSensor.countStation > 0" class="pb-4">
              <div class="primary--text" style="font-size: 24px;">
                STATION
              </div>
              <div>
                <measurement-table :sensorData="stationData"
                                   :showAction="permission.includes('manage measurement') || permission.includes('view measurement info')"
                                   type="station" v-on:deleteBtn="openDelete" v-on:editBtn="openEdit"
                                   v-on:viewBtn="openView"/>
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div class="rightPanel">
      <div id="ppMap" class="fill-height"></div>
    </div>
    <setting-point v-model="dialogItem" :factoryId="selectedSensor.id" :initialLat="selectedSensor.lat"
                   :initialLon="selectedSensor.lon" :isShow="openDialog" :mode="dialogMode"
                   v-on:isShowSignal="handleDialogSignal" v-on:queryResult="handleQueryResult"/>
    <v-dialog v-model="openDeleteDialog" max-width="600">
      <v-card>
        <v-card-title>
          <v-spacer/>
          <v-icon @click="openDeleteDialog=false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pa-0">
          <div class="py-9 black--text text-center" style="font-size: 20px;">
            <span v-if="!isFinishDelete && !isFailDelete">ยืนยันการลบ "<span class="error--text">{{ deleteSelect.code }}</span>"</span>
            <span v-else-if="isFinishDelete" class="success--text">ลบจุดระบายมลพิษ/จุดตรวจวัดสำเร็จ</span>
            <span v-else-if="isFailDelete" class="warning--text">ลบจุดระบายมลพิษ/จุดตรวจวัดไม่สำเร็จ</span>
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="d-flex justify-end" style="width: 100%">
            <v-btn elevation="0" @click="openDeleteDialog=false">
              ปิด
            </v-btn>
            <v-btn v-if="!isFinishDelete && !isFailDelete" class="ml-3" color="search-item white--text" elevation="0"
                   @click="confirmDelete()">
              ยืนยัน
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CompanyListItem from '../CompanyListItem'
import SettingPoint from '../dialog/setting/Point'
import { mapState } from 'vuex'
import MeasurementTable from '../measurementTable'

export default {
  name: 'PollutionPointSetting',
  components: {
    MeasurementTable,
    SettingPoint,
    CompanyListItem
  },
  props: ['initialFactory'],
  async mounted () {
    if (this.initialFactory.id) {
      this.keyword = this.initialFactory.name
      this.factoryId = this.initialFactory.id
    }
    setTimeout(this.doMounted, 100)
  },
  data () {
    return {
      detailTab: 0,
      filterType: '',
      filterOption: [
        {
          text: 'ทั้งหมด',
          value: 'all'
        },
        {
          text: 'CEMS',
          value: 'cems'
        },
        {
          text: 'OPMS',
          value: 'opms'
        },
        {
          text: 'MOBILE',
          value: 'mobile'
        },
        {
          text: 'STATION',
          value: 'station'
        }
      ],
      sortBy: '',
      sortSelectItems: [
        {
          text: 'ระยะทางจากกึ่งกลางแผนที่',
          value: 'distance'
        },
        {
          text: 'ชื่อโรงงาน (A-Z)',
          value: 'az'
        },
        {
          text: 'ชื่อโรงงาน (Z-A)',
          value: 'za'
        }
      ],
      cemsData: [],
      opmsData: [],
      mobileData: [],
      stationData: [],
      map: null,
      sensorData: [],
      selectedSensor: {},
      isFetchingSensor: false,
      keyword: '',
      factoryId: 0,
      openDialog: false,
      openDeleteDialog: false,
      dialogMode: '',
      dialogItem: {},
      isFinishDelete: false,
      isFailDelete: false,
      deleteSelect: '',
      markerIcon: require('@/assets/img/marker.png')
    }
  },
  methods: {
    async doMounted () {
      // If not set timeout longdo map can not scroll
      await this.createLongdoMap()
      this.map.location({ lat: 13.721874, lon: 100.530351 })
      this.map.zoom(12)
      await this.$store.dispatch('dashboard/clearState')
      await this.get(this.keyword)
    },
    async createLongdoMap () {
      // create longdo map
      this.map = new window.longdo.Map({
        placeholder: document.getElementById('ppMap')
      })
      try {
        // manage cache longdo map layer
        await (window.longdoJsInterface.Util.cacheLongdoMapLayer(window.longdo, 'diw_front_cache', this.map))
      } catch (e) {
        console.warn('At cacheLongdoMapLayer', e)
      }
      try {
        this.map.Layers.setBase(window.longdo.Layers.POI)
        this.map.enableFilter(window.longdo.Filter.Light)
      } catch (e) {
        console.warn('At [setBase or enableFilter]', e)
      }
      if (this.$vuetify.breakpoint.xs) {
        this.map.Ui.Zoombar.visible(false)
      }
    },
    async clearResult () {
      this.sensorData = []
      await this.$store.dispatch('dashboard/clearState')
      this.map.Overlays.clear()
    },
    async enterSearch () {
      await this.clearResult()
      await this.get(this.keyword)
    },
    async get (keyword = '') {
      const uuid = this.uuid || ''
      const payload = {
        uuid: uuid,
        ignore_visible: 1,
        device: this.deviceId || '',
        measurement: ['cems', 'opms', 'mobile', 'station'].indexOf(this.filterType) + 1 // if select cems --> 1
      }
      if (!keyword || this.factoryId) {
        payload.factory = this.factoryId
      } else {
        payload.keyword = keyword
      }
      await this.getSensor(payload)
    },
    async getSensor (payload) { // to be call from this.get(keyword)
      this.isFetchingSensor = true
      await this.$store.dispatch('dashboard/getFactoryList', payload)
      this.sensorData = this.allFactoryData
      if (this.sensorData.length < 14 && !this.isFetchingSensor && this.factoryHasNextPage) {
        await this.get(this.keyword)
        return
      }
      this.placeFactory(this.sensorData) // clear all then place all
      this.isFetchingSensor = false
    },
    async getTable (isClear = false) {
      const uuid = this.uuid || ''
      if (this.selectedSensor.countCems > 0) {
        this.$store.dispatch('manage/getMeasurementList', { id: this.selectedSensor.id, type: 1, date: '', uuid: uuid, clear: isClear })
          .then((data) => { this.cemsData = data })
      }
      if (this.selectedSensor.countOpms > 0) {
        this.$store.dispatch('manage/getMeasurementList', { id: this.selectedSensor.id, type: 2, date: '', uuid: uuid, clear: isClear })
          .then((data) => { this.opmsData = data })
      }
      if (this.selectedSensor.countMobile > 0) {
        this.$store.dispatch('manage/getMeasurementList', { id: this.selectedSensor.id, type: 3, date: '', uuid: uuid, clear: isClear })
          .then((data) => { this.mobileData = data })
      }
      if (this.selectedSensor.countStation > 0) {
        this.$store.dispatch('manage/getMeasurementList', { id: this.selectedSensor.id, type: 4, date: '', uuid: uuid, clear: isClear })
          .then((data) => { this.stationData = data })
      }
    },
    placeFactory (data) {
      this.map.Overlays.clear()
      for (const item of data) {
        const marker = new window.longdo.Marker(
          { lon: item.lon, lat: item.lat },
          {
            title: item.name,
            icon: { url: this.markerIcon }
          }
        )
        this.map.Overlays.add(marker)
      }
    },
    scrollListToTop () {
      const dom = document.getElementsByClassName('sensorListContainer')[0]
      dom.scroll({ top: 0, left: 0, behavior: 'smooth' })
    },
    onSensorListScroll ({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight * 0.9 && !this.isFetchingSensor && this.factoryHasNextPage) {
        this.get(this.keyword)
      }
    },
    moveMapToLocation (location) {
      this.map.location(location)
      this.map.zoom(16)
    },
    handleDialogSignal (e) {
      if (this.openDialog !== e) {
        this.openDialog = e
      }
    },
    openAdd () {
      this.dialogMode = 'add'
      this.dialogItem = { id: 0, params: [], visible: [], lat: null, lon: null, channel: [] }
      this.openDialog = true
    },
    openView (item) {
      this.dialogMode = 'read'
      const data = { ...item }
      const geom = String(data.geom).replace('POINT', '').replace('(', '').replace(')', '')
      const lonlat = geom.split(' ')
      data.lon = parseFloat(lonlat[0])
      data.lat = parseFloat(lonlat[1])
      data.type = data.typeCode
      delete data.geom
      delete data.date
      delete data.typeCode
      const key = Object.keys(data)
      data.params = []
      key.forEach(
        (value, index) => {
          if (!isNaN(value)) {
            data.params.push(parseInt(value))
            delete data[value]
          }
        }
      )
      this.dialogItem = data
      this.openDialog = true
    },
    openEdit (item) {
      this.dialogMode = 'edit'
      const data = { ...item }
      const geom = String(data.geom).replace('POINT', '').replace('(', '').replace(')', '')
      const lonlat = geom.split(' ')
      data.lon = parseFloat(lonlat[0])
      data.lat = parseFloat(lonlat[1])
      data.type = data.typeCode
      delete data.geom
      delete data.date
      delete data.typeCode
      const key = Object.keys(data)
      data.params = []
      key.forEach(
        (value, index) => {
          if (!isNaN(value)) {
            data.params.push(parseInt(value))
            delete data[value]
          }
        }
      )
      this.dialogItem = data
      this.openDialog = true
    },
    openDelete (item) {
      this.deleteSelect = item
      this.isFinishDelete = false
      this.isFailDelete = false
      this.openDeleteDialog = true
    },
    async confirmDelete () {
      const payload = { id: this.deleteSelect.id, uuid: this.uuid || '' }
      const isSuccess = await this.$store.dispatch('sensor/deleteSensor', payload)
      if (isSuccess) {
        this.isFinishDelete = true
        await this.getTable(true)
      } else {
        this.isFailDelete = true
      }
    },
    resizeEvent () {
      this.map.resize()
    },
    goToDetail (item) {
      this.detailTab = 1
      this.selectedSensor = { ...item }
      this.getTable()
    },
    handleQueryResult (isSuccess) {
      if (isSuccess) {
        this.getTable(true)
      }
    }
  },
  watch: {
    filterType (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.clearResult()
        this.get(this.keyword)
      }
    },
    initialFactory: {
      handler (to, from) {
        if (to.id && to.id !== from.id) {
          this.keyword = to.name
          this.factoryId = to.id
          this.detailTab = 0
          this.enterSearch()
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState('dashboard', ['factoryHasNextPage', 'allFactoryData']),
    ...mapState('user', ['uuid', 'permission']),
    ...mapState(['deviceId'])
  }
}
</script>

<style scoped>
@media (min-width: 600px) and (max-width: 701px) {
  .detailTab {
    height: calc(100% - 84px);
  }
}

@media (min-width: 701px) {
  .detailTab {
    height: calc(100% - 48px);
  }
}

@media (max-width: 600px) {
  .view {
    display: flex;
    height: 100%;
    flex-direction: column-reverse;
  }

  .leftPanel {
    width: 100%;
    height: 70%;
    overflow-y: auto;
  }

  .detailTab {
    height: 100%;
  }

  .rightPanel {
    width: 100%;
    height: 30%;
  }
}

@media (min-width: 600px) {
  .view {
    display: flex;
    height: 100%;
  }

  .leftPanel {
    width: 50%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
  }

  .rightPanel {
    width: 50%;
    height: 100%;
  }
}

.searchListRow:hover {
  background-color: #EEEEEE !important;
  cursor: pointer;
}

.sensorListContainer {
  width: 100%;
  overflow-x: hidden;
  height: calc(100% - 64px);
  overflow-y: auto;
}

::v-deep .v-data-table-header > tr > th {
  background-color: #D8D8D8 !important;
  padding: 0 8px !important;
  color: black;
}

::v-deep .v-data-table-header > tr > th {
  border-left: thin solid rgba(0, 0, 0, 0.3) !important;
  border-top: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table-header > tr > th:last-child {
  border-right: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:first-child > td {
  border-top: unset;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:first-child {
  border-left: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:last-child {
  border-right: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:last-child > td {
  border-bottom: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:not(:first-child) {
  border-left: thin solid rgba(0, 0, 0, 0.12) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:hover {
  background-color: #e5eeff !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:nth-child(odd) {
  background-color: #F6F6F6;
}
</style>
