<template>
  <div style="height: 100%; width: 100%;">
    <div id="setting-instrument-header"
         :class="(isShowFilter || !$vuetify.breakpoint.xs)?'pt-2':''"
         :style="(isShowFilter || !$vuetify.breakpoint.xs)?'height: unset;':'height: 56px; overflow: hidden;'"
         class="d-flex flex-wrap justify-sm-center mt-3 pl-3"
         style="width: 100%; background-color: rgba(65, 149, 24, 0.2); position: relative;">
      <div v-if="$vuetify.breakpoint.xs" style="position: absolute; top: 4px; right: 8px;">
        <v-icon class="white rounded-xl" style="font-size: 18px;" @click="isShowFilter=!isShowFilter">
          {{ (isShowFilter) ? 'mdi-minus' : 'mdi-plus' }}
        </v-icon>
      </div>
      <div v-if="$vuetify.breakpoint.xs && !isShowFilter" class="py-1" style="height: 56px; width: 100%;">
        <div v-for="label in filterLabel" :key="label" class="text-center" style="width: 100%;">
          {{ label }}
        </div>
      </div>
<!--      <div class="d-flex align-center">-->
<!--        <div class="myLabelWidth d-flex align-center pr-3 pb-2 search&#45;&#45;text">-->
<!--          ตั้งแต่วันที่-->
<!--        </div>-->
<!--        <div class="myInputWidth pr-3 pb-2">-->
<!--          <my-date-picker v-model="option.datefrom"/>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="d-flex align-center">-->
<!--        <div class="myLabelWidth d-flex align-center pr-3 pb-2 search&#45;&#45;text">-->
<!--          ถึงวันที่-->
<!--        </div>-->
<!--        <div class="myInputWidth pr-3 pb-2">-->
<!--          <my-date-picker v-model="option.dateto" :min="option.datefrom"/>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div v-if="factory_id === 0" class="d-flex align-center">-->
<!--        <div class="myLabelWidth d-flex align-center pr-3 pb-2 search&#45;&#45;text">-->
<!--          โรงงาน-->
<!--        </div>-->
<!--        <div class="myInputWidth pr-3 pb-2">-->
<!--          <v-autocomplete v-model="option.factory" :items="factoryOptions" background-color="white" color="search" dense-->
<!--                          hide-details item-color="search-item" item-text="name" item-value="id" outlined-->
<!--                          placeholder="&#45;&#45;เลือก&#45;&#45;" @change="option.type=null"></v-autocomplete>-->
<!--        </div>-->
<!--      </div>-->
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center pr-3 pb-2 search--text">
          ประเภทจุดตรวจวัด
        </div>
        <div class="myInputWidth pr-3 pb-2">
          <v-select v-model="option.type" :items="measurementTypeOptions" background-color="white" dense hide-details
                    item-text="name" item-value="id" outlined placeholder="ทั้งหมด"
                    @change="option.measurement=null"></v-select>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center pr-3 pb-2 search--text">
          จุดตรวจวัด
        </div>
        <div class="myInputWidth pr-3 pb-2">
          <v-select v-model="option.measurement" :items="forSelectMeasurement" background-color="white" dense hide-details
                    item-text="name" item-value="id" outlined placeholder="ทั้งหมด"
                    @change="option.parameter=null"></v-select>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center pr-3 pb-2 search--text">
          พารามิเตอร์
        </div>
        <div class="myInputWidth pr-3 pb-2">
          <v-autocomplete v-model="option.parameter" :items="extendedParameterList" background-color="white"
                          color="search" dense hide-details item-color="search-item" item-text="name" item-value="id"
                          outlined placeholder="ทั้งหมด"></v-autocomplete>
        </div>
      </div>
      <div class="pr-3 pb-2">
        <v-btn color="primary white--text" style="height: 40px;" @click="clickSearch()">
          ค้นหา
          <v-progress-circular v-show="isLoadingList" class="ml-3" indeterminate size="16"></v-progress-circular>
        </v-btn>
      </div>
    </div>
    <v-container :style="`height: calc(100% - ${headerHeight}px);`" class="pt-3">
      <div v-if="permission.includes('manage instrument')" class="pb-3">
        <v-btn @click="openCleanDialog()">
          <v-icon class="mr-2">mdi-plus</v-icon>
          เพิ่มปัจจัยการตรวจสอบ
        </v-btn>
      </div>
      <v-data-table id="instrumentList_dataTable" :headers="permissionHeader" :items="items" :items-per-page="999"
                    :style="dataTableStyle" disable-sort fixed-header hide-default-footer mobile-breakpoint="0">
        <template v-slot:header.conditionNormal="{ header }">
          <div class="chip success white--text text-center" style="font-weight: normal; min-width: 80px;">
            {{ header.text }}
          </div>
        </template>
        <template v-slot:header.conditionWarning="{ header }">
          <div class="chip warning white--text text-center" style="font-weight: normal; min-width: 80px;">
            {{ header.text }}
          </div>
        </template>
        <template v-slot:header.conditionSevere="{ header }">
          <div class="chip error white--text text-center" style="font-weight: normal; min-width: 100px;">
            {{ header.text }}
          </div>
        </template>
        <template v-slot:item.parameter.name="{ item }">
          {{ item.parameter.name }} <span style="font-size: 11px;">({{ item.unit }})</span>
        </template>
        <template v-slot:item.conditionNormal="{ item }">
          {{ item.conditionNormal.conditionString }}
        </template>
        <template v-slot:item.conditionSevere="{ item }">
          {{ item.conditionSevere.conditionString }}
        </template>
        <template v-slot:item.conditionWarning="{ item }">
          {{ item.conditionWarning.conditionString }}
        </template>
        <template v-slot:item.stdValue="{ item }">
          {{ item.stdValue ? numberWithCommas(item.stdValue) : '-' }}
        </template>
        <template v-slot:item.min_max="{ item }">
          {{ numberWithCommas(item.min || '') }} - {{ numberWithCommas(item.max || '') }}
        </template>
        <template v-slot:item.actions="{ item }" class="d-flex align-center">
          <v-icon v-if="permission.includes('view instrument info') && !permission.includes('manage instrument')"
                  class="mr-2" @click="showDialog=true; dialogMode='view'; dialogData=JSON.parse(JSON.stringify(item))">
            mdi-magnify
          </v-icon>
          <v-icon v-if="permission.includes('manage instrument')" class="mr-2"
                  @click="showDialog=true; dialogMode='edit'; dialogData=JSON.parse(JSON.stringify(item))">
            mdi-pencil
          </v-icon>
          <v-icon v-if="permission.includes('manage instrument')" @click="activeDeleteDialog(item)">
            mdi-trash-can
          </v-icon>
        </template>
      </v-data-table>
    </v-container>
    <factor-dialog v-model="dialogData" :initialFactory="initialFactory" :is-show="showDialog" :mode="dialogMode"
                   v-on:isShowSignal="dialogSignal" v-on:querySuccessSignal="get"/>
    <v-dialog v-model="openDeleteDialog" max-width="600">
      <v-card>
        <v-card-title>
          <v-spacer/>
          <v-icon @click="openDeleteDialog=false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pa-0">
          <div class="py-9 black--text text-center" style="font-size: 20px;">
            <span v-if="!isFinishDelete && !isFailDelete">
              ยืนยันการลบ "<span class="error--text">{{ deleteSelect.measurement.name }}</span>"
            </span>
            <span v-else-if="isFinishDelete" class="success--text">สำเร็จ</span>
            <span v-else-if="isFailDelete" class="warning--text">ไม่สำเร็จ</span>
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="d-flex justify-end" style="width: 100%">
            <v-btn elevation="0" @click="openDeleteDialog=false">
              ปิด
            </v-btn>
            <v-btn v-if="!isFinishDelete && !isFailDelete" class="ml-3" color="search-item white--text" elevation="0"
                   @click="confirmDelete()">
              ยืนยัน
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FactorDialog from '../dialog/setting/FactorDialog'
import utils from '../../assets/js/utils'
import { mapState } from 'vuex'
import MyDatePicker from '../myDatePicker'

export default {
  name: 'VerificationFactor',
  // eslint-disable-next-line vue/no-unused-components
  components: { MyDatePicker, FactorDialog },
  props: ['initialFactory'],
  async mounted () {
    if (this.initialFactory.id) {
      this.option.factory = this.initialFactory.id
    } else if (this.factory_id !== 0) {
      this.option.factory = this.factory_id
    } else {
      this.measurementTypeForSelect = await this.$store.dispatch('ref/getMeasurementType')
      this.currentMeasurement = await this.$store.dispatch('ref/measurement', {})
    }
    this.reCalculateHeight()
    window.addEventListener('resize', this.reCalculateHeight)

    this.$store.dispatch('ref/getMeasurementType').then() // suppress IDE warning by adding .then()
    if (this.factory_id !== 0) {
      // if user is factoryStaff
      const tmp = await this.$store.dispatch('ref/getFactoryAll')
      this.filteredFactory = tmp.filter(item => String(item.id) === String(this.factory_id))
    } else if (this.province_id !== 0) {
      // if user is provinceStaff
      this.filteredFactory = await this.$store.dispatch('ref/getFactoryByProvinceId', { provinceId: this.province_id })
    } else {
      // if user is not factory staff / province staff
      // if user is admin
      this.filteredFactory = await this.$store.dispatch('ref/getFactoryAll')
    }
    await this.get()
    this.reCalculateHeight()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.reCalculateHeight)
  },
  data () {
    return {
      isShowFilter: true,
      isLoadingList: false,
      headerHeight: 40,
      measurementTypeForSelect: [],
      myParameterList: [],
      headers: [
        {
          text: 'โรงงาน',
          value: 'factory.name',
          align: 'center'
        },
        {
          text: 'ประเภทจุดตรวจวัด',
          value: 'measurementType.name',
          align: 'center'
        },
        {
          text: 'รหัสจุดตรวจวัด',
          value: 'measurement.name',
          align: 'center'
        },
        {
          text: 'พารามิเตอร์',
          value: 'parameter.name',
          align: 'center'
        },
        {
          text: 'ปกติ',
          value: 'conditionNormal',
          align: 'center'
        },
        {
          text: 'เฝ้าระวัง',
          value: 'conditionWarning',
          align: 'center'
        },
        {
          text: 'แจ้งเตือน',
          value: 'conditionSevere',
          align: 'center'
        },
        {
          text: 'ค่ามาตรฐาน',
          value: 'stdValue',
          align: 'center'
        },
        {
          text: 'ช่วงการตรวจวัด',
          value: 'min_max',
          align: 'center'
        }
      ],
      filteredFactory: [],
      items: [],
      showDialog: false,
      option: {
        datefrom: null,
        dateto: null,
        factory: null,
        type: null,
        measurement: null,
        parameter: null
      },
      dialogData: {
        ignore_condition: false,
        factory: {},
        parameter: {},
        measurement: {},
        conditionNormal: { leftValue: '', rightValue: '' },
        conditionWarning: { leftValue: '', rightValue: '' },
        conditionSevere: { leftValue: '', rightValue: '' }
      },
      deleteSelect: {
        measurement: {}
      },
      currentMeasurement: [],
      openDeleteDialog: false,
      isFinishDelete: false,
      isFailDelete: false,
      dialogMode: ''
    }
  },
  methods: {
    reCalculateHeight () {
      const dom = document.getElementById('setting-instrument-header')
      if (dom) {
        this.headerHeight = dom.offsetHeight
      }
    },
    openCleanDialog () {
      this.dialogData = {
        ignore_condition: false,
        factory: {},
        parameter: {},
        measurement: {},
        conditionNormal: { leftValue: '', rightValue: '' },
        conditionWarning: { leftValue: '', rightValue: '' },
        conditionSevere: { leftValue: '', rightValue: '' }
      }
      this.dialogMode = 'add'
      this.showDialog = true
    },
    numberWithCommas (x) {
      return utils.numberWithCommas(x)
    },
    dialogSignal (e) {
      if (this.showDialog !== e) {
        this.showDialog = e
      }
    },
    async get () {
      const payload = {
        ...this.option,
        uuid: this.uuid
      }
      this.isLoadingList = true
      this.items = []
      this.items = await this.$store.dispatch('manage/getInstrumentList', payload)
      this.isLoadingList = false
    },
    clickSearch () {
      this.get()
    },
    activeDeleteDialog (item) {
      this.isFinishDelete = false
      this.isFailDelete = false
      this.deleteSelect = JSON.parse(JSON.stringify(item))
      this.openDeleteDialog = true
    },
    confirmDelete () {
      const payload = {
        id: this.deleteSelect.id,
        uuid: this.uuid
      }
      const isSuccess = this.$store.dispatch('manage/deleteInstrument', payload)
      if (isSuccess) {
        this.isFinishDelete = true
        this.get()
      } else {
        this.isFailDelete = true
      }
    }
  },
  watch: {
    initialFactory: {
      handler (to, from) {
        if (to.id && to.id !== from.id) {
          this.option.parameter = 0
          this.option.factory = this.initialFactory.id
          this.get()
        }
      },
      deep: true
    },
    async 'option.factory' (to, from) {
      if (!to) {
        this.measurementTypeForSelect = await this.$store.dispatch('ref/getMeasurementType')
        this.currentMeasurement = await this.$store.dispatch('ref/measurement', {})
        return
      }
      this.currentMeasurement = await this.$store.dispatch('ref/measurement', { id_parent: to })
      const uniqueMeasurementType = this.currentMeasurement.reduce(
        // array.concat(any) return new array
        (accumulator, current) => accumulator.includes(current.type_id) ? accumulator : accumulator.concat(current.type_id), []
      ).map(item => parseInt(item))
      const tmp = this.measurementType.filter(item => uniqueMeasurementType.includes(item.id))
      if (tmp.length <= 0) {
        this.measurementTypeForSelect = []
      } else {
        this.measurementTypeForSelect = [{ id: '', name: 'ทั้งหมด' }, ...tmp]
      }
    },
    async 'option.measurement' (to, from) {
      if (!to || to === '') {
        return undefined
      }
      this.myParameterList = await this.$store.dispatch('ref/getParameterList', { id_parent: to })
    },
    isShowFilter (to, from) {
      setTimeout(this.reCalculateHeight, 100)
    },
    isLoadingList (to, from) {
      setTimeout(this.reCalculateHeight, 100)
    }
  },
  computed: {
    ...mapState('user', ['uuid', 'permission', 'factory_id', 'province_id']),
    ...mapState('ref', ['factory', 'measurementType']),
    filterLabel () {
      const ret = []
      ret.push(`ตั้งแต่วันที่ - ${utils.dateToThai(this.option.datefrom) || 'ไม่เลือก'}`)
      ret.push(`ถึงวันที่ - ${utils.dateToThai(this.option.dateto) || 'ไม่เลือก'}`)
      return ret
    },
    permissionHeader () {
      const width = (this.permission.includes('manage instrument')) ? 100 : 50
      if (this.permission.includes('manage instrument') || this.permission.includes('view instrument info')) {
        return [...this.headers, { text: '', sortable: false, value: 'actions', width: width }]
      } else {
        return this.headers
      }
    },
    extendedParameterList () {
      if (this.myParameterList.length <= 0) {
        return []
      }
      return [{ id: 0, name: 'ทั้งหมด' }, ...this.myParameterList]
    },
    forSelectMeasurement () {
      if (this.currentMeasurement.length <= 0) {
        return []
      }
      if (this.option.type) {
        const tmp = this.currentMeasurement.filter(item => String(item.type_id) === String(this.option.type))
        if (tmp.length <= 0) {
          return []
        }
        return [{ id: '', name: 'ทั้งหมด' }, ...tmp]
      } else {
        return [{ id: '', name: 'ทั้งหมด' }, ...this.currentMeasurement]
      }
    },
    measurementTypeOptions () {
      return [{ id: '', name: 'ทั้งหมด' }, ...this.measurementTypeForSelect]
    },
    factoryOptions () {
      return [{ id: '', name: 'ทั้งหมด' }, ...this.filteredFactory]
    },
    dataTableStyle () {
      return !this.permission.includes('manage instrument') ? 'height: 100%' : 'height: calc(100% - 48px);'
    }
  }
}
</script>

<style scoped>
.chip {
  padding: 2px 8px;
  border-radius: 24px !important;
}

@media (max-width: 600px) {
  .myLabelWidth {
    width: 105px;
  }

  .myInputWidth {
    max-width: calc(100vw - 12px - 105px - 86px);
  }
}

@media (min-width: 600px) {
  .myInputWidth {
    max-width: 170px;
  }
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  min-height: 48px;
  padding: 4px 16px;
}

::v-deep .v-data-table__wrapper {
  max-height: 100%;
  overflow-y: unset;
}

::v-deep .v-data-table-header > tr > th {
  background-color: #D8D8D8 !important;
  padding: 0 8px !important;
  color: black;
}

::v-deep .v-data-table-header > tr > th {
  border-left: thin solid rgba(0, 0, 0, 0.3) !important;
  border-top: thin solid rgba(0, 0, 0, 0.3) !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table-header > tr > th:last-child {
  border-right: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:first-child > td {
  border-top: unset;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:first-child {
  border-left: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:last-child {
  border-right: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:last-child > td {
  border-bottom: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:not(:first-child) {
  border-left: thin solid rgba(0, 0, 0, 0.12) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:hover {
  background-color: #e5eeff !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:nth-child(odd) {
  background-color: #F6F6F6;
}
</style>
